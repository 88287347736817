.news {
  padding-top: 24px;
  padding-bottom: 24px;

  &-row {
    img {
      width: 100%;
      object-fit: cover;
    }
  }

  &-info {
    padding: 0 12px;

    h1 {
      margin: 0;
      font-size: 46px;
    }
    h3 {
      font-size: 24px;
      font-weight: 500;
    }
  }

  &-desc {
    padding: 0 12px;
  }
}

@media screen and (min-width: 768px) {
  .news {
    &-row {
      display: flex;
      padding: 0 20px;
      margin-bottom: 30px;
      justify-content: space-between;

      img {
        width: 100%;
        max-width: 50%;
      }
    }

    &-info {
      padding: 0 30px;
          line-height: normal;
    }

    &-desc {
      padding: 0 20px;
    }
  }
}

@media screen and (min-width: 1200px) {
  .news {
    &-row {
      padding: 0;
    }

    &-info {
      padding: 0 50px;
    }

    &-desc {
      padding: 0;
    }
  }
}