.main-dialog {
    background-color: #fff;
    padding: 40px;
    overflow: hidden;

    .form__field {
        margin-bottom: 12px !important;
    }
    
    .main-content {
        width: 100%;
        // overflow-x: auto;
        transition: all .3s ease;
        position: relative;
    
        &.hidden {
            // width: 0;
            opacity: 0;
            position: absolute;
            visibility: hidden;
            top: 0;
            height: 0;
            display: none;
        }
        
    
        .title {
            font-style: normal;
            font-weight: 600;
            line-height: 100%;
            color: #1F1F1F;
        }
    
        .description {
            font-family: Roboto;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 160%;
            color: #414D5F;
        }
    
        .button {
            margin: 0 auto;
        }
        .styled-text {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 100%;
            color: #414D5F;
            opacity: 0.5;
        }
        .user-form {
            max-width: 100%;
            position: relative;

            .orders-list {
                // position: absolute;
                width: 100%;
                height: 0;
                background: #EBF0F8;
                border-radius: 25px;
                overflow-y: auto;
                transition: all .3s ease;
                &::-webkit-scrollbar {
                    width: 0;
                }

                .list-item {
                    padding: 16px 25px;
                    cursor: pointer;
                    transition: .3s all ease;
                    border-radius: 25px;

                    &:hover {
                        background-color: #f2f5f9;
                    }
                }
            }
        }
    }
}
.dialog-wrapper {
    // .MuiDialog-scrollPaper {
    //     align-items: flex-start;
    //     position: relative;
    // }

    // .MuiPaper-root {
    //     position: absolute;
    //     height: fit-content;
    //     overflow: hidden;
    //     background-color: transparent;
    //     top: 30px;
    //     .close-icon {
    //         position: absolute;
    //         top: 0;
    //         right: -10px;
    //     }
    // }
    .MuiDialog-paper {
        overflow-x: hidden;
    }
}

.results-by-search {
    .dialog-table {
        background: #EBF0F8;
        border-radius: 4px 4px 0px 0px;
        padding: 15px 0;
    
        .tab-title {
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 18px;
            color: #1F1F1F;
    
        }
    }
    .products-all {
        // overflow: auto;
        // max-height: 230px;

        &::-webkit-scrollbar {
            width: 4px;
        }
        &::-webkit-scrollbar-thumb {
            background-color: #FFBE00;
            border-radius: 4px;
        }
    }
    .product-all-item {
        border-bottom: 1px solid #EFEFEF;
        .product-item-title {
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 140%;
            color: #1F1F1F;
            display: -webkit-box;
            max-width: 400px;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        img {
            max-width: 60px;
            width: 100%;
        }
    }
    .title {
        font-size: 16px;
    }
    .result-bold-text {
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 140%;
        color: #1F1F1F;
    }
    .total-price {
        display: flex;
        justify-content: flex-end;
        span {
            font-weight: 700;
        }
    }
}
.add-comment {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 120%;
    color: #315EFB;
}
.textarea-field {
    max-height: 100px;
    
    .form__field-textarea {
        height: 100%;
    }
}

.step-item {
    // overflow-x: auto;
    transition: .3s all ease;
    position: absolute;
    left: 0;
    opacity: 0;
    height: 0; 
    visibility: hidden;  

    &.active {
        visibility: visible;  
        height: auto;
        opacity: 1;
        position: static;
    }
}
.no-scroll {
    ::-webkit-scrollbar {
        display: none;
    }
}
.wrong-text-bg {
    background-color: #FBF5F5;
    color: #1F1F1F;
    font-weight: 500;
    border-radius: 10px;
    padding: 10px;
}
.cause-selector {
    position: relative;
    width: 100%;
    ::-webkit-scrollbar {
        display: none;
    }
    .selector-title {
        padding: 12px 0px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        font-size: 14px;
    }

    .cause-title {
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 140%;
        color: #315EFB;
    }

    svg {
        transition: all .3s ease;

        &.svg-rotate {
            transform: rotate(180deg);
        }
    }

    .selector-list {
        min-width:152px;
        width: 100%;
        max-width: 100%;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 100%;
        background: #fff;
        height: 0;
        overflow: auto;
        z-index: 100;
        transition: .3s all ease;
        opacity: 0;
        visibility: hidden;
        border-radius: 4px;
        border: 1px solid rgb(234, 234, 234);

        ul {
            padding: 0;

            li {
                padding: 4px 8px;
                border-radius: 4px;
                transition: all .3s ease;
                &:hover {
                    background-color: #EBF0F8;
                }
            }
        }

        &.open {
            opacity: 1;
            visibility: visible;
        }
    }
}
.text-center {
    text-align: center;
}