
.btn-disabled {
  color: $black;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  border: none;
  border-radius: 90px;
  padding: 12px 20px;
  box-sizing: border-box;
  font-family: $font-meri;
  font-weight: 600;
  font-size: 13px;
  line-height: 120%;
  cursor: pointer;
  transition: 0.3s;
}

.btn {
  background-color: $yellow;
  color: $black;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  border: none;
  border-radius: 90px;
  padding: 12px 20px;
  box-sizing: border-box;
  font-family: $font-meri;
  font-weight: 600;
  font-size: 13px;
  line-height: 120%;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    background-color: #ffe000;
  }

  &-icon-left {
    svg {
      margin-right: 8px;
    }
  }

  &-icon-right {
    svg {
      margin-left: 8px;
    }
  }


  &-transparent {
    background-color: transparent;
    padding: 0;
    color: inherit;

    &:hover {
      background-color: transparent;
    }
  }

  &-home-slider {
    max-width: 248px;
    height: 56px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    //font-size: 16px;
  }

  &-white {
    background-color: $white;

    &:hover {
      background-color: $white;
    }
  }

  &-big {
    height: 56px;
    max-width: 248px;
    width: 100%;
    font-weight: 600;
    font-size: 16px;
  }

  &--w100 {
    max-width: 100%;
    width: 100%;
  }

  &-single-product {
    font-family: $font-meri;
    font-size: 13px;
    line-height: 100%;
    color: #414D5F;
    padding: 10px 20px;
    background: #EBF0F8;
    border-radius: 4px;

    &:hover {
      background-color: #EBF0F8;
    }

    svg {
      margin-right: 10px;
    }
  }

  &-show__more {
    background-color: transparent;
    color: $blue;
    position: relative;
    padding: 0 0 10px;
    width: fit-content;
    text-decoration: underline;

    &:hover {
      text-decoration: unset;
      background-color: transparent;
    }

  }

  &-outline {
    background-color: transparent;
    border: 2px solid $yellow;

    &:hover {
      background-color: transparent;
    }
  }

  &-table {
    background-color: #EBF0F8;
    width: 38px;
    height: 38px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: #EBF0F8;
    }
  }
}

.svg-group {
  position: relative;

  span {
    position: absolute;
    top: -12px;
    right: -2px;
    width: 15px;
    height: 15px;
    font-weight: 700;
    font-size: 9px;
    line-height: 120%;
    text-align: center;
    color: $black;
    background-color: $yellow;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-like span {
    right: -9px;
  }
}