.modal {
  justify-content: center;
  align-items: center;
  // position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  transition: opacity .2s ease;
  right: 0;
  z-index: 998;
  background: rgba(31, 31, 31, 0.6);
  width: 100%;
  height: 100vh;
  overflow: auto;
  box-sizing: border-box;

  .button-close {
    cursor: pointer;
    position: absolute;
    top: 15px;
    right: 15px;
  }


  &-content {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 600px;
    width: 100%;
    padding: 40px;
    background: $white;
    box-shadow: 0 5px 6px rgba(0, 0, 0, 0.02);
    border-radius: 4px;
    transition: all .2s ease;
    z-index: 999;
    overflow: hidden;
    overflow-y: auto;
    height: fit-content;
  }

  &-header {
    display: flex;
    align-self: center;
    justify-content: space-between;
    padding-bottom: 20px;

    span {
      font-size: 24px;
    }


  }


  &-body {
    .modal-title {
      font-weight: 600;
      color: #1F1F1F;
    }
  }

  &-product {
    .modal-content {
      max-width: 792px;
      @media screen and (max-width: 1200px) {
           max-width:700px;
      }
      @media screen and (max-width: 900px) {
        max-width:650px;
   }
   @media screen and (max-width: 750px) {
    max-width:580px;
}
@media screen and (max-width: 650px) {
  max-width:380px;
}
    }

    &__title {
      font-weight: 600;
      font-size: 24px;
      line-height: 160%;
    }

    &__inner {
      display: flex;
      margin-top: 20px;
    }

    &__img {
      max-width: 160px;
      width: 100%;
      margin-right: 30px;

      img {
        width: 100%;
      }
    }

    &__info {
      &-header {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        font-weight: 600;
        font-size: 24px;
        line-height: 100%;
      }

      &-title {
        font-size: 24px;
        line-height: 100%;
        margin-bottom: 5px;
      }

      &-code {
        color: $blue;
      }

      &-desc {
        font-family: $font-meri;
        font-size: 16px;
        line-height: 160%;
        color: #414D5F;
        margin-top: 15px;
      }

      &-block {
        // display: flex;
        // justify-content: space-between;
        margin-top: 15px;
      }

      &-footer {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 20px;

        @media screen and(max-width: 450px) {
          flex-direction: column;
          gap: 10px;
          // align-items: end;

          .btn-big {
            max-width: 100%;
          }
        }
      }

      &-price {
        font-weight: bold;
        font-size: 24px;
        line-height: 100%;
        margin-right: 10px;

        &__count {
          font-weight: 500;
          font-size: 12px;
          color: #414D5F;
          display: block;
          margin-top: 4px;
        }
      }

    }
  }

  &-info {
    .modal-content {
      padding: 30px 40px;
      max-width: 495px;
    }
  }

  &-title {
    font-size: 24px;
    line-height: 100%;
  }

  &-description {
    // font-family: $font-meri;
    font-size: 16px;
    line-height: 160%;
    color: #414D5F;
  }

  &-arrange {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    transition: opacity .2s ease;
    right: 0;
    z-index: 998;
    background: rgba(31, 31, 31, 0.6);
    width: 100%;
    height: 100vh;
    overflow: auto;
    box-sizing: border-box;
    display: none;

    &__content {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      padding: 40px;
      box-sizing: border-box;
      width: 100%;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      .button-close {
        cursor: pointer;
        position: absolute;
        top: -8px;
        right: -27px;
      }
    }

    &__item {
      background-color: $white;
      box-shadow: 0 5px 6px rgba(0, 0, 0, 0.02);
      border-radius: 4px;
      padding: 40px;
      box-sizing: border-box;
      margin-right: 8px;
      width: 100%;
      min-height: 552px;

      &:nth-last-child(1) {
        margin-right: 0;
      }

      &s {
        display: flex;
        margin-right: 8px;
      }
    }

    &__order {
      max-width: 522px;
    }

    &__print {
      max-width: 484px;
      background: #F5F7FB;
      border-radius: 4px 4px 0 0;
    }

    &__tabs {
      font-weight: 600;
      font-size: 13px;
      line-height: 100%;

      li {
        margin-right: 12px;
      }
    }

    &__template {
      padding: 40px 0 40px 20px;
      max-width: 270px;

      &-description {
        font-family: $font-meri;
        font-weight: 500;
        font-size: 12px;
        line-height: 120%;
        color: #414D5F;
        margin-top: 8px;
      }

      &-link {
        font-weight: 600;
        font-size: 12px;
        line-height: 120%;
        color: $yellow;
      }
    }
  }
}

.order {
  .form__field-input {
    background: #EBF0F8;
    font-weight: 600;
    border-color: #EBF0F8;
    color: #1F1F1F;
  }

  .form__field-label {
    font-weight: 600;
    color: #1F1F1F;
  }

  .form__field {
    margin-bottom: 12px;

    &.focused,
    &.not-empty {
      .form__field-label {
        color: $blue;
      }
    }
  }
}

.print {
  padding-bottom: 27px;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 9px;
    left: 0;
    bottom: -9px;
    // background: url("../img/check.png");
  }

  &-title {
    font-weight: 500;
    font-size: 24px;
    line-height: 100%;

    span {
      font-weight: 600;
    }
  }

  &-subtitle {
    font-weight: 700;
    font-size: 16px;
    line-height: 160%;
  }

  &-block {
    margin-top: 20px;

    &__white {
      padding: 10px 15px;
      background: $white;
      border-radius: 5px;
    }
  }

  &-list {
    padding: 0;
  }

  &-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    line-height: 160%;
    margin-bottom: 10px;


  }

  &-left {
    font-weight: 600;
    margin-right: 10px;

    &__red {
      color: $red;
    }
  }

  &-right {
    max-width: 126px;
    width: 100%;
    font-weight: 500;
  }

  &-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__title {
      font-weight: 600;
      font-size: 16px;
      line-height: 100%;
    }

    &__link {
      font-weight: 500;
      font-size: 11px;
      line-height: 160%;
      color: rgba(#414D5F, 0.5);

      a {
        color: $yellow;
      }
    }
  }
}

.template {
  padding-bottom: 0;

  &__list {
    // font-family: $font-meri;
    font-size: 13px;
    line-height: 16px;
    color: #414D5F;
    padding: 0;
    margin-right: 7px;
    margin-top: 10px;
    height: 420px;
    overflow-y: scroll;

    &::-webkit-scrollbar-track {
      background-color: $white;
    }

    &::-webkit-scrollbar {
      width: 2px;
      background-color: $white;
    }

    &::-webkit-scrollbar-thumb {
      width: 4px;
      background-color: $yellow;
    }
  }

  &__item {
    margin-bottom: 10px;
    margin-right: 12px;
    padding: 15px;
    box-sizing: border-box;
    opacity: 0.65;
    position: relative;
    background: #EBF0F8;
    border-radius: 4px;

    &-list {
      padding: 0;
      margin-top: 8px;

      li {
        display: flex;
        margin-bottom: 6px;

        &:nth-last-child(1) {
          margin-bottom: 0;
        }
      }
    }


    &-title {
      font-weight: 600;
      font-size: 13px;
      line-height: 100%;
    }

    &-delete {
      position: absolute;
      bottom: 15px;
      right: 15px;
      cursor: pointer;
    }

    .icon {
      margin-right: 7px;

      svg {
        rect,
        path {
          fill: #414D5F;
        }
        &.change-stroke{
          rect,
          path {
            fill: transparent;
            stroke: #414D5F;
          }
        }
      }
    }

    &.active {
      opacity: 1;

      .template__item-title {
        color: $blue;
      }
      .icon {
        svg {
          rect,
          path {
            fill: $blue;
          }
          &.change-stroke{
            rect,
            path {
              fill: transparent;
              stroke: $blue;
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .modal {
    &-arrange {
      &__content {
        height: 100vh;
        overflow-y: auto;
        display: block;
        width: fit-content;
        padding: 0 10px 40px;

        .button-close {
          top: 20px;
          right: 15px;
        }
      }

      &__order {
        margin-right: 0;
        margin-bottom: 8px;
        margin-top: 40px;
      }

      &__print {
        max-width: 522px;
        margin-bottom: 40px;
      }

      &__item {
        padding: 20px;

        .form__field {
          max-width: 100%;
          width: 100%;
        }
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .modal {
    &-content {
      padding: 20px;
    }

    &-product {
      &__inner {
        flex-direction: column;
      }

      &__info-block {
        flex-direction: column-reverse;
      }
    }

  }
  .print-footer {
    display: block;

    .btn {
      margin-top: 10px;
    }
  }
}