.promotion {
  &__list {
    .slick-list {
      margin: 0 -10px;
    }

    .slick-track {
      display: flex;
    }

    .slick-slide {
      margin: 0 10px;
      height: auto;
    }
  }


  &__item {
    display: flex !important;
    border-radius: 4px;
    overflow: hidden;
    background-color: $white;
  }

  &__left {
    position: relative;
    min-width: 50%;
    width:60%;
    // max-width: 380px;
    // width: 100%;

    img {
      width: 100%;
      // height: 100%;
      // object-fit: cover;
      // min-height: 220px;
    }

    span {
      position: absolute;
      top: 10px;

      &.info {
        padding: 4px 8px;
        box-sizing: border-box;
        background-color: $yellow;
        font-weight: 800;
        font-size: 10px;
        line-height: 160%;
        text-transform: uppercase;
        left: 10px;
        border-radius: 4px;
      }

      &.percent {
        
          height: 100%;
          padding: 4px 8px;
          box-sizing: border-box;
          background-color: #FF5252;
          color: #fff;
          top: 0;
          right: 0px;
          font-weight: 800;
          font-size: 13px;
          line-height: 160%;
          border-radius: 4px;
          position: absolute;
          display: flex;
          align-items: center;
      
      }
    }
  }

  &__right {
    width:100%;
    padding: 24px 20px 16px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    span {
      font-weight: bold;
    }
  }

  &__title {
    font-weight: bold;
    font-size: 18px;
    line-height: 120%;
  }

  &__description {
    font-size: 15px;
    color: #414D5F;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    // display: -webkit-box;
    // max-width: 400px;
    // -webkit-line-clamp: 2;
    // -webkit-box-orient: vertical;
    // overflow: hidden;
    // text-overflow: ellipsis;
  }

  &__footer {
    font-weight: 500;
    font-size: 14px;
    line-height: 160%;
  }
}

@media screen and (max-width: 767px) {
  .promotion {
    &__item {
      flex-direction: column;
    }

    &__left {
      min-width: 100%;
      max-width: 100%;
      width: 100%;
    }

    &__right {
      padding: 15px;
      height: 100%;
    }

    &__description {
      margin: 10px 0;
    }
    .swiper-slider .swiper-wrapper {
      height: 442px;
    }
  }
}
