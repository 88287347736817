.single-product {
  &-block {
    background: $white;
    box-shadow: 0 5px 6px rgba(0, 0, 0, 0.02);
    border-radius: 4px;
    padding: 20px 10px;
    box-sizing: border-box;
    height: 100%;

    &.info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  &-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 767px) {
      flex-direction: column;

      .single-product-stars {
        justify-content: flex-start;
        margin-top: 5px;
      }

      .single-product-code {
        text-align: left;
        margin-top: 5px;
      }
    }
  }

  &-title {
    font-weight: 600;
    font-size: 24px;
    line-height: 100%;
  }

  &-like {}

  &-code {
    font-weight: 600;
    font-size: 24px;
    line-height: 100%;
    text-align: right;
    color: #315EFB;
  }

  &-desc {
    font-family: $font-meri;
    font-size: 16px;
    line-height: 160%;
    color: #414D5F;
  }

  &-stars {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-family: $font-meri;
    font-size: 12px;
    line-height: 100%;
    text-decoration-line: underline;
    color: #777777;
    white-space: nowrap;
  }

  &-btns {
    display: flex;
    margin-top: 15px;
    // justify-content: space-between;
    gap: 10px;

    @media (max-width: 580px) {
      flex-direction: column;
      justify-content: flex-start;
    }


    .btn {
      margin-bottom: 10px;
      max-width: 32%;
      width: 100%;

      // .button-text {
      //   white-space: nowrap;
      //   overflow: hidden;
      //   text-overflow: ellipsis;
      // }

      .button-icon {
        width: 15px;
        height: 15px;
        margin-right: 7px;
      }

      @media (max-width: 992px) {
        max-width: 33%;
      }

      @media (max-width: 580px) {
        max-width: 100%;
        justify-content: flex-start;
        margin-bottom: 0;
      }

      &:nth-last-child(1) {
        margin-right: 0;
      }
    }
  }

  &-table {
    font-family: $font-meri;
    color: #414D5F;

    &__item {
      padding: 0 20px;
      margin-bottom: 8px;
    }

    &__title {
      font-family: $font-meri;
      font-weight: 500;
      font-size: 16px;
      line-height: 140%;
      margin-bottom: 8px;

    }

    &__count {
      font-size: 16px;
      line-height: 140%;
      margin-bottom: 8px;
    }

    &__more {
      font-weight: 500;
      font-size: 16px;
      line-height: 140%;
      color: $blue;
      padding-left: 30px;
    }

    &__price {
      font-weight: normal;
      font-size: 16px;
      line-height: 140%;
    }

    &__bg {
      background: #EBF0F8;
      border-radius: 4px;
      padding: 10px 0;
    }

    &__bg1 {
      padding: 10px 0;
    }
  }

  &__offer-price {
    position: relative;
    text-align: right;
    padding-top: 30px;
    font-size: 11px;

    &_btn {
      position: relative;
      font-size: 11px;
      font-weight: 700;
      color: #0066FF;
      padding: 0;
      border: none;
      background-color: transparent;
      cursor: pointer;

      &:hover {
        &::before {
          opacity: 1;
        }
      }

      &::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: #0066FF;
        opacity: 0;
        transition: 0.3s;
      }
    }

    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      background: #EFEFEF;
      left: 0;
      top: 18px;
      border-radius: 2px;
    }
  }

  &__form {
    display: grid;
    gap: 20px;
    width: 100%;

    label{
      position: absolute;
      top: 50%;
      right: 15px;
      transform: translate(0, -50%);
      font-size: 13px;
      font-weight: 400;
      color: #414D5F;
    }

    input {
      width: 100%;
      height: 56px;
      box-sizing: border-box;
      padding: 0 25px;
      border-radius: 40px;
      border: none;
      background-color: #EBF0F8;
      font-size: 13px;
      font-weight: 600;
      color: #1F1F1F;

      &[type=number]::-webkit-inner-spin-button,
      &[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      &::placeholder {
        font-size: 13px;
        font-weight: 600;
        color: #1F1F1F;
      }
    }

    &_submit-btn{
      width: 248px;
      height: 56px;
      font-size: 16px;
      font-weight: 600;
      @media(max-width: 768px){
        width: 100%;
      }
    }
  }

  &__footer {
    display: flex;
    // flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 8px 0;

    .single-footer-btns {
      max-width: 100%;
      width: 100%;
      justify-content: end;
    }

    @media (max-width: 580px) {
      flex-direction: column;
      align-items: start;

      .btn {
        margin-top: 10px;
        width: 100%;
        max-width: 100%;
      }

      .single-footer-btns {
        flex-direction: column;
        align-items: end;
        margin-top: -30px;
      }
    }

  }

  &__price-block {
    .single-product__old-price {
      font-weight: 500;
      font-size: 12px;
      line-height: 100%;
      color: #777777;
      margin-bottom: 4px;
    }

    .single-product__price {
      font-weight: bold;
      font-size: 24px;
      line-height: 100%;
      color: $red;
    }

  }

  &__info {
    padding: 12px 10px;
    background: #EBF0F8;
    border-radius: 4px;
    font-family: $font-meri;
    font-weight: normal;
    font-size: 12px;
    line-height: 100%;
    color: #414D5F;
    margin: 10px 3px;

    &-title {
      margin-bottom: 4px;
    }

    &-price {
      font-weight: 700;
      font-size: 16px;
    }
  }

  &__sliders {
    display: flex;
    min-height: 320px;
    height: 100%;
    position: relative;
  }

  &__parent {
    max-width: calc(100% - 90px);
    width: 512px;
    margin: 0 auto;

    @media screen and (max-width: 767px) {
      max-width: calc(100% - 90px);
    }

    &-item {
      display: flex;
      justify-content: center;

      img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 450px;
        z-index: -1;
        object-fit: contain;

        @media screen and (max-width: 500px) {
          margin-top: 50px;
        }
      }
    }
  }

  &__children {
    max-width: 90px;
    width: 100%;
    padding: 0;
    margin-left: 0;
    margin-right: 0;

    &-item {
      min-height: 54px;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
      }

      border: 2px solid transparent !important;

      &.swiper-slide-thumb-active {
        border: 2px solid $yellow !important;
        box-sizing: border-box;
        border-radius: 4px;
      }
    }
  }

  &__elements {
    .products-block__slick {
      position: absolute;
      bottom: 0;
      right: 10px;
      z-index: 1000;

      .prev {
        margin-right: 0;
      }

      .next {
        margin-left: 8px;
      }

      .prev,
      .next {
        width: 38px;
        height: 38px;
        background: #EBF0F8;

        svg path {
          stroke: #414D5F;
        }
      }
    }
  }

  &__tags {
    display: flex;
    position: absolute;
    top: 0;
    right: 10px;

    .single-product__tag {
      margin-right: 10px;

      &:nth-last-child(1) {
        margin-right: 0;
      }
    }

    .info {
      padding: 4px 8px;
      box-sizing: border-box;
      background-color: #FFBE00;
      font-weight: 800;
      font-size: 10px;
      line-height: 160%;
      text-transform: uppercase;
      border-radius: 4px;
      text-align: center;
      position: relative;
      z-index: 3;
    }

    .percent {
      padding: 4px 8px;
      box-sizing: border-box;
      background-color: $red;
      color: $white;
      right: 10px;
      font-weight: 800;
      font-size: 10px;
      line-height: 160%;
      border-radius: 4px;
    }
  }

  &s {
    display: flex;
    flex-wrap: wrap;

    .product__inline {
      margin-right: 20px;
      max-width: calc((100% / 5) - 20px);

      @media screen and (max-width: 1550px) {
        max-width: calc((100% / 4) - 20px);
      }

      @media screen and (max-width: 950px) {
        max-width: calc((100% / 3) - 20px);
      }

      @media screen and (max-width: 750px) {
        max-width: calc((100% / 2) - 20px);
      }

      @media screen and (max-width: 500px) {
        max-width: 100%;
        margin: 0 0 20px;
      }
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;

    .list {
      margin-right: 32px;

      &:nth-last-child(1) {
        margin-right: 0;
      }
    }
  }

  &__descriptions {
    display: flex;
    flex-wrap: wrap;
    white-space: pre-wrap;

    &-block {
      margin-right: 20px;
      max-width: 287px;
      width: 100%;

      &:nth-last-child(1) {
        margin-right: 0;
      }
    }

    &-title {
      font-size: 18px;
      line-height: 160%;
      margin-bottom: 12px;
    }
  }

  &__receipt {
    display: flex;
    flex-wrap: wrap;

    @media screen and (max-width: 767px) {
      flex-direction: column;
    }

    .single-product__descriptions {
      max-width: calc(100% - 308px);
      width: 100%;

      @media screen and (max-width: 767px) {
        max-width: 100%;
      }
    }

    &-list {
      padding: 0;
      max-width: 288px;
      width: 100%;
      margin-right: 20px;
    }

    &-item {
      background: #EBF0F8;
      border-radius: 4px;
      height: 34px;
      font-weight: 600;
      font-size: 13px;
      line-height: 100%;
      color: #414D5F;
      margin-bottom: 10px;
      padding: 0 10px;
      display: flex;
      align-items: center;

      &.active {
        background: #315EFB;
        border-radius: 4px;
        color: $white;
      }
    }
  }

  &__form {
    &-title {
      font-size: 18px;
      line-height: 160%;
      margin-bottom: 20px;
    }

    &-footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  &-list {
    font-family: $font-meri;
    font-size: 16px;
    line-height: 140%;
    color: #414D5F;
    padding: 13px 0 11px;
    width: 100%;

    &.half-fill {
      width: 50%;

      @media (max-width: 580px) {
        width: 100%;
      }
    }

    .multiplication {
      font-weight: 500;
      color: $blue;

      @media (min-width: 1380px) and (max-width: 1440px) {
        font-size: 10px;
      }
    }

    .count {
      margin-left: 8px;
      white-space: nowrap;
    }

    .price {
      // width: 75%;
      display: block;
      @media (min-width:1380px) and (max-width:1440px) {
        font-size: 12px !important;
      }

      & > em {
      @media (min-width:1380px) and (max-width:1440px) {
          font-size: 12px !important;
        }      
      }

      & > em > em {
      @media (min-width:1380px) and (max-width:1440px) {
        font-size: 12px !important;
      }
      }
    }

    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      font-size: 16px;
      font-weight: 600;

      .title {
        // max-width: 110px;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &.empty {
        opacity: 0.5;
      } 
    }

    .no-quantity {
      color: #BBBBBB,
    }

    &__bg {
      padding: 13px 20px 5px;
      border-radius: 4px;
      background: #EBF0F8;
      width: 50%;
      //background: red;

      @media (min-width:1380px) and (max-width:1440px) {
        padding: 9px 10px 5px;
      }
    }
  }
}