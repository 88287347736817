$theme_color1: #2a4256;
$theme_color2: #315EFB;;
$theme_color2_hover: darken($theme_color2, 10%);
$header_bg_color: $theme_color1;
$spacing: 8px;
$max_width_container: 1280px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1168px;
$textColorGray: #c0c0c0;
$borderColorGray: #7a7a7a;
$darkBlueColor: #001b2d;
$border_gray_color: #eee;
$border_gray_color2: #ccc;

$border_type1: 1px solid $border_gray_color ;


