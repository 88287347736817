// .product {
//     &-list {
//         display: flex;
//         gap: 20px;
//         overflow-x: auto;

//         &::-webkit-scrollbar {
//             // height: 0;
//             width: 0;
//         }
//     }
//     .swiper-wrapper {
//         height: 400px;
//     }
//     &-column {
//         display: flex;
//         flex-direction: column;
//         justify-content: space-between;
//         // max-width: 350px;
//         width: 100%;
//         gap: 20px;

//         &.slider {
//             min-width: 290px;
//             overflow: hidden;

//             & .swiper-pagination {
//                 padding-bottom: 20px;
//             }

//             & .product-inner {
//                 width: 100%;
//                 padding-bottom: 20px;

//                 .line {
//                     width: 100%;
//                     height: 1px;
//                     background-color:#EFEFEF;
//                     margin: 10px 0;
//                 }
//             }

//             & .product-item {
//                 flex-direction: column;
//                 align-items: flex-start;

//                 & .product__img{
//                     width: 100%;
//                     max-height: 215px;
//                 }
                
//             }

//             & .product__title {
//                 font-style: normal;
//                 font-weight: 600;
//                 font-size: 14px;
//                 line-height: 160%;
//                 color: #1F1F1F;
//             }

//             & .product-code {
//                 font-style: normal;
//                 font-weight: 600;
//                 font-size: 14px;
//                 line-height: 160%;
//                 color: #FFBE00;
//             }
//         }
//     }
//     &-item {
//         background: #FFFFFF;
//         display: flex;
//         // justify-content: space-between;
//         align-items: center;
//         cursor: pointer;
//         border-radius: 4px;
//         padding: 20px;
//         width: 100%;
//         height: 100%;
//         position: relative;
//         z-index: 1;
//         flex-direction: column;

//         & .product-bg {
//             position: absolute;
//             width: 100%;
//             height: 100%;
//             border-radius: 4px;
//             opacity: 0.29;
//             top: 0;
//             left: 0;
//             z-index: 0;
//         }
        

//         &-title {
//             font-style: normal;
//             font-weight: 600;
//             font-size: 20px;
//             line-height: 140%;
//             color: #1F1F1F;
//             display: flex;
//             // flex-direction: column;
//             gap: 10px;
//             margin-bottom: 40px;
//             align-items: center;
//             justify-content: center;
//             z-index: 1;

//             span {
//                 color: #999999;
//             }
//         }

//         &-image {
//             width: 220px;
//             height: 220px;
//             position: relative;
//             margin-left: 10px;

//             img {
//                 position: relative;
//                 z-index: 1;
//             }

//             &::before {
//                 content: "";
//                 width: 85%;
//                 height: 85%;
//                 border-radius: 100%;
//                 background-color: #FFBE00;
//                 position: absolute;
//                 transform: translate(-50%, -50%);
//                 top: 50%;
//                 left: 50%;
//                 z-index: 0;
//             }
//         }
//     }
// }

.product {
    &-list {
        display: flex;
        gap: 20px;
    }
    .swiper-wrapper {
        height: 400px;

        @media screen and(max-width: 450px) {
            // height: auto;
        }
    }
    &-column {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        // max-width: 350px;
        width: 100%;
        gap: 20px;

        &.slider {
            max-width: 290px;
            min-width: 290px;
            overflow: hidden;

            &-swiper {
                max-width: 100%;
            }

            & .swiper-pagination {
                padding-bottom: 20px;
            }

            & .product-inner {
                width: 100%;
                padding-bottom: 20px;

                .line {
                    width: 100%;
                    height: 1px;
                    background-color:#EFEFEF;
                    margin: 10px 0;
                }
            }

            & .product-item {
                flex-direction: column;
                align-items: flex-start;

                & .product__img{
                    width: 100%;
                    max-height: 215px;
                }
                
            }

            & .product__title {
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 160%;
                color: #1F1F1F;
            }

            & .product-code {
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 160%;
                color: #FFBE00;
            }
        }
    }
    &-item {
        background: #FFFFFF;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        border-radius: 4px;
        padding: 20px;
        width: 100%;
        height: 100%;
        position: relative;

        & .product-bg {
            position: absolute;
            width: 100%;
            height: 100%;
            border-radius: 4px;
            opacity: 0.29;
            top: 0;
            left: 0;
            z-index: 0;
        }

        &-title {
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 140%;
            color: #1F1F1F;
            display: flex;
            flex-direction: column;
            z-index: 1;

            span {
                color: #999999;
            }
        }

        &-image {
            width: 130px;
            height: 130px;
            position: relative;
            margin-left: 10px;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                position: relative;
                z-index: 1;
            }

            &::before {
                content: "";
                width: 100px;
                height: 100px;
                border-radius: 100%;
                background-color: #FFBE00;
                position: absolute;
                transform: translate(-50%, -50%);
                top: 50%;
                left: 50%;
                z-index: 0;
            }
        }
    }
}