$brand-color: #473FEC;
$block-border-radius: 40px;
$green: #66D53F;
$orange: #F0BA2E;

$dark_yellow: #FFA000;
$yellow: #FFBE00;
$blue: #315EFB;
$red:#FF5252;
$white: #fff;
$black: #1F1F1F;
$grey: #999999;

$dashboard_color1: #1F1F1F;
$dashboard_color2: #555F7E;
$dashboard_color3: #1C2A53;
$dashboard_color4: #8E95A9;
$dashboard_color5: #F4F6FB;
$dashboard_color6: #F8F8F8;
$dashboard_line: #E9EAF3;
$dashboard_red: #FF392B;
$dashboard_green: #279F51;
$dashboard_link: #2F80ED;

//$font-fira: 'Fira Sans', sans-serif;
$font-meri: 'Montserrat', sans-serif;
$font-roboto: 'Roboto', sans-serif;