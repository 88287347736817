.category {
  &-block {
    // display: none;
  }

  &-list {
    padding: 0;
    font-weight: 600;
    font-size: 14px;
    line-height: 120%;
    margin-top: 15px;
  }

  &-item {
    margin-bottom: 15px;

    display: block !important;
    &.active {

      .category-item__header {
        color: $blue;

        &::before {
          width: 8px;
          height: 5px;
          background-image: url("../../images/category-arrow.svg");
        }
      }

      .category-item__child {
        display: block;
      }
    }

    &__header {
      position: relative;
      padding: 0 20px;
      cursor: pointer;

      &::before {
        content: '';
        position: absolute;
        width: 6px;
        height: 9px;
        left: 5px;
        top: 50%;
        transform: translateY(-50%);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        background-image: url("../../images/category-arrow1.svg");
      }

      &:hover {
        color: $blue;
      }
    }


    svg {
      margin-right: 10px;
    }

    &__child {
      padding: 0 21px;
      margin-top: 10px;
      display: none;

      li {
        margin-bottom: 10px;
        font-weight: 500;
        font-size: 14px;
        cursor: pointer;

        &:hover {
          color: $blue;
        }

        &.active {
          color: $blue;

          svg rect {
            fill: $blue;
          }
        }

        svg {
          margin-right: 5px;
        }

        &:nth-last-child(1) {
          margin-bottom: 0;
        }
      }
    }
  }
}