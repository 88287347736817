.tab {
  &s {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    font-weight: 600;
    font-size: 18px;
    line-height: 100%;

    li {
      position: relative;
      color: rgba(#414D5F, 0.5);
      margin-right: 28px;
      margin-bottom: 8px;
      padding-bottom: 6px;
      transition: 0.3s;

      &:nth-last-child(1) {
        margin-right: 0;
      }

      &.active {
        color: $blue;

        &:before {
          content: '';
          position: absolute;
          width: 100%;
          height: 2px;
          bottom: 0;
          left: 0;
          background-color: $blue;
          border-radius: 2px;
        }
      }

      &:hover {
        color: $blue;
      }
    }
  }

  &-content {
    margin: 20px 0;
  }

  &-pane {
    display: none;

    &.active {
      display: block;
    }
  }
}