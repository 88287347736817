.table {
  width: 100%;
  border-collapse: collapse;
  border-radius: 4px;
  overflow: hidden;

  thead {
    background: #EBF0F8;
  }

  th {
    padding: 20px 20px 16px;
    font-weight: 700;
    font-size: 15px;
    line-height: 130%;
    text-align: left;
  }

  td {
    padding: 10px 20px 3px;
    text-align: left;
    font-weight: 600;
    font-size: 14px;
    line-height: 160%;
  }

  tbody tr {
    border-bottom: 1px solid #eeeeee;
    background-color: $white;
  }

  &--center {
    th {
      text-align: center;
    }

    td {
      text-align: center;
    }
  }

  &-title {
    font-weight: bold;
    font-size: 18px;
    line-height: 160%;
    margin-bottom: 15px;
  }

  &-block {
    background: $white;
    box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.02);
    border-radius: 4px;
  }

  &-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 5px 20px 15px;

    &-info {
      font-weight: 500;
      font-size: 16px;
      line-height: 160%;
      text-align: right;
      margin-right: 85px;

    }
  }
}

@include media-breakpoint-down(sm) {
  .table {
    thead {
      display: none;
    }

    tr {
      display: flex;
      flex-wrap: wrap;
      padding: 15px;
      border-bottom: 1px solid #D2D7E0;

      &:last-child {
        border-bottom: none;
      }
    }

    td {
      padding: 0;
      width: 100%;
      margin-bottom: 5px;

      &:last-child {
        margin-bottom: 0;
      }

      span {
        color: #777;
      }
    }

    &--center {
      td {
        text-align: left;
      }
    }

    &-footer {
      flex-direction: column;
      align-items: end;

      &-info {
        text-align: right;
        margin-right: 0;

      }
    }
  }
}