.review {
  position: relative;
  padding-bottom: 20px;
  margin-bottom: 24px;

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: #D5E6F1;
    border-radius: 2px;
    bottom: 0;
    left: 0;
  }

  &s {

  }

  &-header {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;

    .rating {
      font-size: 26px;
    }
  }

  &-name {
    font-weight: 600;
    font-size: 18px;
    line-height: 100%;

  }

  &-date {
    font-size: 15px;
    font-weight: 400;
  }

  &-body {
  }

  &-desc {
    font-family: $font-meri;
    font-size: 15px;
    line-height: 140%;
    color: #414D5F;
    margin-bottom: 10px;
  }

  &-title {
    font-family: $font-meri;
    font-weight: bold;
    font-size: 15px;
    line-height: 160%;
    color: #414D5F;
    margin-bottom: 5px;
  }

  &-inner {
    padding-left: 20px;
    position: relative;

    .review {
      margin-top: 17px;
      margin-bottom: 0;
      padding-bottom: 0;

      &:before {
        content: unset;
      }
    }

    &:before {
      content: '';
      position: absolute;
      background: url(../../images/replace.svg) no-repeat;
      background-size: cover;
      width: 12px;
      height: 9px;
      left: 0;
      top: 3px;
    }
  }

  &-stars__block {
    display: flex;
    margin-bottom: 30px;
    @media screen and (max-width: 550px) {
      flex-direction: column;
    }
    &-info {
      max-width: 113px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .rating {
        font-size: 26px;
      }
    }

    &-rating {
      font-weight: 600;
      font-size: 70px;
      line-height: 120%;
      text-align: center;
    }

    &-desc {
      font-weight: 500;
      font-size: 11px;
      line-height: 170%;
      text-align: center;
      color: #414D5F;
    }

    &-lines {
      width: 100%;
    }

    &-line {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 7px;
      &:nth-last-child(1){
        margin-bottom: 0;
      }

      .line {
        width: 100%;
        max-width: 470px;
        height: 9px;

        margin: 0 15px;
        background: #E1E1E1;
        border-radius: 4px;
        position: relative;
        @media screen and (max-width: 550px) {
          margin-left: 0;
          margin-right: 15px;
        }
        .line-inner{
          position: absolute;
          background: #FFBE00;
          border-radius: 4px;
          left: 0;
          top: 0;
          height: 100%;
        }
      }

      .rating {
        font-size: 20px;
        margin-right: 15px;
      }

      .count {
        font-weight: 500;
        font-size: 14px;
        line-height: 140%;
      }
    }
  }
}