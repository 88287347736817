.slots {
  &-list {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    font-weight: bold;
    font-size: 16px;
    line-height: 120%;
  }

  &-item {
    display: flex;
    margin-right: 20px;
    margin-bottom: 10px;
    max-width: 440px;

    &:nth-last-child(1) {
      margin-right: 0;
    }

    .icon {
      width: 52px;
      height: 52px;
      background: $white;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      margin-right: 14px;
      transition: 0.3s;

      &:hover {
        border: 1px solid $blue;
      }
    }

    .content {
      width: calc(100% - 64px);
    }

    .title {
      font-size: 16px;
      line-height: 120%;
      margin: 16px 0 0;

    }

    .description {
      font-size: 16px;
      line-height: 140%;
      color: #414D5F;
      margin: 10px 0;
    }

    ul {
      padding: 0;
    }

    .contact__list {
      li {
        font-weight: bold;
        font-size: 16px;
        line-height: 120%;
        margin-bottom: 8px;
      }
    }
  }
}
.image-block{
  display: flex;
  justify-content: flex-end;
  width: 100%;
}