.form {
  &__field {
    position: relative;

    &.focused, &.not-empty {
      .form {
        &__field-label {
          top: 10px;
          //font-weight: bold;
          font-size: 10px;
          color: $yellow;
        }
      }
    }

    &.textarea-field {
      width: 100%;
    }

    .select2-container--default {

      .select2-selection--single {
        height: 56px;
        border: none;
        background-color: transparent;

        .select2-selection__rendered {
          background-color: #EBF0F8;
          border: 1px solid #EBF0F8;
          height: 100%;
          display: flex;
          align-items: center;
          border-radius: 56px;
          padding: 0 25px;
          font-family: $font-meri;
          font-weight: 600;
          font-size: 13px;
          line-height: 120%;
          color: #1F1F1F;
        }

        .select2-selection__arrow {
          // background-image: url(../img/svg/chevron-down.svg);
          background-repeat: no-repeat;
          background-position: center left;
          top: 15px;
          right: 25px;

          b {
            display: none;
          }
        }
      }
    }
  }


  &__field-input {
    width: 100%;
    height: 56px;
    border: 1px solid #E3EAF6;
    background-color: $white;
    border-radius: 50px;
    padding: 25px 25px 10px 35px;
    //font-weight: bold;
    font-size: 13px;
    line-height: 130%;
    font-family: $font-meri;
    color: #777777;

    &:focus {
      outline: none;
    }
  }

  &__field-input--normal {
    padding: 0 25px;
  }

  &__field-label {
    pointer-events: none;
    position: absolute;
    top: 20px;
    left: 27px;
    color: #777777;
    font-weight: 500;
    font-size: 13px;
    line-height: 120%;
    transition: 0.3s;
  }

  &__row {
    width: 100%;

    .checkbox {
      width: max-content;
    }
  }

  &__link {
    font-size: 14px;
    line-height: 120%;
    font-family: $font-meri;
    color: #777777;
    position: relative;
    display: inline-block;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      border-bottom: 1px solid #777;
      transition: 0.3s;
    }

    &:hover {
      color: #000;

      &::after {
        border-bottom-color: #000;
        width: 0;
      }
    }
  }

  &__list--short {
    .form {
      &__list-item {
        margin-bottom: 10px;
      }
    }
  }

  &__list-item {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__field-textarea {
    width: 100%;
    height: 150px;
    border: 1px solid #E3EAF6;
    background-color: $white;
    border-radius: 20px;
    padding: 25px 25px 10px 35px;
    //font-weight: bold;
    font-size: 13px;
    line-height: 130%;
    font-family: $font-meri;
    color: #777777;
    resize: none;

    &:focus {
      outline: none;
    }

    .form__field-label {
      top: 20px;
    }
  }
}

.inner-row {
  margin: 0 -5px;
}

.inner-col {
  padding-left: 5px;
  padding-right: 5px;
}

.checkbox {
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 0;
  width: 100%;
  cursor: pointer;

  &__input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    display: none;

    &:checked ~ .checkbox__marker {
      background-color: $yellow;
      border-color: $yellow;

      &::after {
        opacity: 1;
      }
    }

    &:checked ~ .checkbox__text {
      color: $yellow;
    }
  }

  &__marker {
    display: block;
    border: 1px solid #999999;
    border-radius: 4px;
    flex-shrink: 0;
    margin-right: 10px;
    position: relative;
    width: 18px;
    height: 18px;
    transition: 0.3s;
    overflow: hidden;
    top: -1px;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url(../../images/checbox-icon.svg);
      background-repeat: no-repeat;
      background-position: center;
      transition: 0.3s;
      opacity: 0;
    }
  }

  &__img {
    width: 40px;
    height: 40px;
    overflow: hidden;
    margin-right: 8px;
    flex-shrink: 0;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__text {
    font-size: 13px;
    line-height: 120%;
    font-weight: 600;
    color: #414D5F;
    transition: 0.3s;

    a {
      color: #A0ABBD;
      text-decoration: underline;
    }
  }

  &:hover {
    .checkbox {
      &__text {
        color: $yellow;
      }
    }
  }
}

.radio {
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 0;
  width: 100%;
  cursor: pointer;

  &__input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    display: none;

    &:checked ~ .radio__marker {
      border-color: $yellow;

      &::after {
        opacity: 1;
      }
    }

    &:checked ~ .radio__text {
      color: $yellow;
    }
  }

  &__marker {
    border: 1px solid #D2D7E0;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #F6F9FE;
    flex-shrink: 0;
    margin-right: 10px;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: linear-gradient(276.14deg, #473EF1 0%, #4D48AF 100%);
      opacity: 0;
      transition: 0.3s;
    }
  }

  &__text {
    font-size: 14px;
    line-height: 120%;
    color: $black;
    transition: 0.3s;

    a {
      color: #A0ABBD;
      text-decoration: underline;
    }
  }

  &:hover {
    .checkbox {
      &__text {
        color: $yellow;
      }
    }
  }
}

.password-group {
  display: flex;
  align-items: center;
  justify-content: center;

  &__input {
    width: 62px;
    height: 62px;
    background: $white;
    border: 1px solid #E3EAF6;
    border-radius: 50%;
    display: block;
    margin: 0 6px;
    line-height: 60px;
    text-align: center;
    font-size: 20px;
    font-family: $font-meri;
    font-weight: 700;
    transition: 0.3s;
    -moz-appearance: textfield;

    &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &:focus {
      outline: none;
      border-color: $yellow;
    }
  }
}

.search-field {
  border: 1px solid #E3EAF7;
  border-radius: 50px;
  background-color: $white;
  width: 100%;
  height: 40px;
  line-height: 38px;
  padding: 0 20px;
  // background-image: url(../img/search-icon.svg);
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: calc(100% - 20px);
}

.sort {
  display: flex;
  align-items: center;

  &__label {
    //font-weight: bold;
    font-size: 14px;
    line-height: 120%;
    margin-right: 0.25rem;
  }

  .select2-container--default {
    .select2-selection--single {
      height: auto;
      border: none;
      background-color: transparent;
      display: flex;
      align-items: center;

      .select2-selection__rendered {
        height: 100%;
        display: flex;
        align-items: center;
        //font-weight: bold;
        font-size: 14px;
        line-height: 120%;
        padding: 0;
        color: #777777;
        background-color: transparent;
      }

      .select2-selection__arrow {
        // background-image: url(../img/chevron-down.svg);
        background-repeat: no-repeat;
        background-position: center left;
        top: 0px;
        right: 0px;
        position: relative;
        margin-left: 6px;

        b {
          display: none;
        }
      }
    }
  }
}