.contact {
  background-color: #EBF0F8;

  &__list {
    margin-bottom: 40px;

    &:nth-last-child(1) {
      margin-bottom: 0;
    }

    &:nth-child(1) {
      svg {
        fill: $blue;
      }

      li {
        font-weight: bold;
        transition: color 0.3s;

        &:hover {
          color: $blue;
        }
      }
    }

    li {
      margin-bottom: 15px;
      transition: color 0.3s;
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 120%;
      //color: #BCBCBC;
      //fill: #BCBCBC;
      display: flex;
      align-items: center;


      svg {
        margin-right: 8px;

        &.viber {
          margin-right: 0;
          margin-left: 8px;
          fill: #7360F2;

        }
      }


      &:last-child {
        margin-bottom: 0;
      }
    }

    a {
      display: flex;
      align-items: center;

      svg {
        margin-right: 8px;

        &.viber {
          margin-right: 0;
          margin-left: 8px;
        }
      }
    }
  }

  &-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

#map {
  width: 100%;
  min-height: 400px;

  iframe {
    width: 100%;
  }


}
.gmnoprint {
  display: none !important;
}
@media screen and (max-width: 1023px) {
  .contact {

    &__list {
      padding-left: 20px;
    }
  }
}