//body .root{
//  background-color: #fff;
//}
 @keyframes loader4 {
  from {transform: translate(-50%, -50%) rotate(0deg)}
  to {transform: translate(-50%, -50%) rotate(360deg)}
}
.tab-item .count-amount {
  position: absolute;
  top: 0;
  right: -15px;
  transform: translateY(-50%);
  width: 18px;
  height: 18px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: #9BA1AD;
  font-size: 12px;
  transition: all .3s ease;
}
.tab-item.active .count-amount {
  background: #315EFB;
}
.tab-item:hover .count-amount {
  background: #315EFB;
}