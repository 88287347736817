.product_tab .MuiTab-root {
  min-width: $spacing*10;
  min-height: 33px;
  @include transition();

  .MuiTypography-h5 {
    font-weight: 500;

  }
}

.product_tab .tab_panel {
  $tab_height: 45px;
  padding-top: $spacing;
  padding-left: $spacing;
  padding-right: $spacing;
  height: calc(100% - #{$tab_height});
  overflow-y: auto;
  background-color: #fff;
  position: relative;

  scrollbar-color: $border_gray_color2 #ecedee; /* «цвет ползунка» «цвет полосы скроллбара» */
  scrollbar-width: thin;


  .custom_bg_block {
    background-repeat: no-repeat;
    position: absolute;
  }

  $scroll_w: 10px;


  &::-webkit-scrollbar-button {
    //background-image: url('');
    //background-repeat: no-repeat;
    width: $scroll_w;
    height: 0;
  }

  &::-webkit-scrollbar-track {
    background-color: #fff;
  }

  &::-webkit-scrollbar-thumb {
    -webkit-border-radius: 5px;
    border-radius: 5px;
    background-color: $border_gray_color2;
    @include transition();
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: darken($border_gray_color2, 20%);
  }

  &::-webkit-resizer {
    //background-image: url('');
    //background-repeat: no-repeat;
    width: $scroll_w;
    height: 0;
  }

  &::-webkit-scrollbar {
    width: $scroll_w;
  }

}


.product_page_container .MuiTable-root .MuiTableRow-root:last-child .MuiTableCell-root {
  border-bottom: none;
}

.product_page_container .product_features_list .MuiListItem-root {
  padding-left: 3px;
  padding-right: 3px;
}

.product_page_container .product_features_list .MuiTableRow-root {
  .value {
    color: $theme_color2;
  }
}

.available_cars .MuiListItem-root {

  .year_container {
    text-align: right;
  }
}

.analogs_list .card_container {

  @include smUp {
    padding-left: $spacing;
    padding-right: $spacing;
  }

  &:focus {
    outline: none;
  }
}

.analogs_list .slick-slide {
  padding: 7px $spacing;

}

.analogs_list .analog_item {
  height: 100%;
  @include border_type1;
  padding: 10px;
  box-shadow: 0 1px 2px $border_gray_color;
  border-radius: 5px;
  background-color: #fff;
  width: auto;
  display: block;
  @include justify-content(space-between);

  &:hover {
    box-shadow: 0 2px 5px #333333;
  }

  .weight {
    color: #546e7a;
  }

  .name,
  .price {
    font-weight: bold;
  }

  .description {
    padding-top: 5px;
    height: 50px;
  }

  .state {
    font-size: 11px;
    color: #629c44;
  }

  .add_to_cart {
    min-width: $spacing*4;
    padding: $spacing / 2;
  }

  .button_container {
    text-align: right;
  }

  .media {
    height: 98px;
  }

  .MuiCardContent-root {
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .show_product_cart {
    @include show_product_cart;
  }

  .brand {
    @include brand;
  }

  .article {
    @include article;
  }

}


.tree_catalog > .MuiListItem-root {
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;

  .MuiButton-root.MuiButtonBase-root {
    //padding: $spacing 0 $spacing $spacing*2;
    border-radius: 0;
    //border-top: 1px solid #fff;
  }


  &.MuiListItem-gutters {
    padding: 0;
  }
}


.tree_catalog .MuiListItem-container {

  .MuiListItem-secondaryAction {
    padding-top: 0;
    padding-bottom: 0;
    border-top: 1px solid #fff;
  }


  .MuiIconButton-edgeEnd {
    margin-right: -$spacing*2;
    border-radius: 0;
    padding: 4px $spacing;
    margin-top: 1px;
    background-color: #ddd;

    &:hover .MuiSvgIcon-root {
      color: $theme_color2;
    }

  }

  .MuiListItemSecondaryAction-root {
    //z-index: -1;

    .MuiSvgIcon-root {
      margin-top: 2px;
    }

  }


  .MuiSvgIcon-root {
    color: #929292;
  }

  .MuiButtonBase-root.MuiListItem-gutters {
    border-top: $border_type1;
  }

  .MuiListItemText-root {
    margin-top: 0;
    margin-bottom: 0;
  }

  a.MuiButton-root {
    //padding-top: 0;
    //padding-left: 0;
    padding-left: $spacing*2;

    &:hover .MuiListItemText-primary {
      color: $theme_color2;
    }
  }


}

.tree_catalog .MuiListItem-root {

  .MuiButton-root {
    @include justify-content(flex-start);
    text-align: left;
  }

  .MuiTypography-root {
    font-weight: 200;
    color: $theme_color1;
  }


  &:hover {
    text-decoration: none;


    .MuiTypography-root {
      @include transition();

      color: $theme_color1;
    }
  }

  a {
    display: block;
    width: 100%;

    &:hover {

      .MuiTypography-root {
        @include transition();
        color: $theme_color2;
      }
    }
  }

}

.sidebar_left_container .sidebar_left_menu .MuiListItem-root {

  .MuiButton-text {
    font-weight: 200;
    color: $theme_color1;
    border-top: $border_type1;
  }


}


.analogs_list .slick-slider .slick-dots li {
  @include slick_dots_li;
}

.search_auto_suggestion li {

  .empty_image img {
    max-width: 100%;
    @include size(80%, auto)
  }

}


.about_brand_page .list_info .MuiListItem-root {

  .MuiListItemIcon-root {
    min-width: $spacing*5;
  }

  .MuiTypography-root {
    color: $borderColorGray;
  }

  .MuiLink-root {
    display: block;
  }

  .MuiLink-root,
  .country {
    color: $theme_color1;
  }

}

.main_search_container.MuiGrid-grid-xs-9 .search_result_vert_prod_block.MuiGrid-item {
  @include full_screen_column(1366px, 4);
  @include full_screen_column(1600px, 5);
  @include full_screen_column(1920px, 6);
}

.main_search_container.MuiGrid-grid-xs-12 .search_result_vert_prod_block.MuiGrid-item {
  @include full_screen_column(1366px, 5);
  @include full_screen_column(1600px, 6);
  @include full_screen_column(1920px, 7);
}

.gridContainerVr {
  @include border_type1;
  display: block;
  padding: 10px;
  @include box-shadow(0, 1px, 2px, $border_gray_color);
  border-radius: 5px;
  @include justify-content(space-between);
  background-color: white;

  .article {
    @include article;
    word-break: break-word;
  }

  .brand {
    @include brand;
  }

  .show_product_cart {
    @include show_product_cart;
  }

  .article_container {
    @include article_container;
  }

  @media (min-width: 1440px) {
    width: calc((100% - 30px) / 4);
  }

  .storage_container {
    min-height: 100px;
  }

}

.cart_columns_container .gridContainerVr {

  .show_product_cart {
    display: inline-block;
  }

}

.search_result_container .gridContainerHr {
  height: 100%;
}

.gridContainerHr {

  .brand {
    @include brand;
  }

  .show_product_cart {
    cursor: pointer;

    &:hover .brand {
      @include transition();
      color: $theme_color2_hover;
    }

  }

  .article_container {
    @include article_container;
  }


  .article {
    @include article;
  }

}

.search_result_product_table_row {
  @include flexbox;

  .brand {
    color: #232323;
    padding: 0;
    font-weight: 600;
    margin-bottom: 5px;
  }

  .brand,
  .article {
    width: 100%;
  }

  .show_product_cart {
    @include show_product_cart;
  }

  .article {
    @include article;
  }

  .grid_item {
    padding: 5px 5px;
    word-wrap: break-word;
    // border-color: $border_gray_color;
    // border-style: solid !important;
    // border-width: 1px;
    border-bottom: 1px solid $border_gray_color;
    @include flex_box_vertical_center;
  }

}

.cart_product_table .cart_row {
  @include flexbox;

  &:hover {
    background-color: #fafafa;
  }

  .show_product_cart {
    @include show_product_cart;
  }

  .brand {
    @include product_item_text_block;
  }

  //.article {
  //  @include product_item_text_block;
  //  font-weight: 500;
  //}

  .article_code_container {
    width: 100%;
  }

  .article {
    @include article;
    font-weight: 500;
  }

  .grid_item {
    padding: 5px 5px;
    word-wrap: break-word;
    border-color: $border_gray_color;
    border-style: solid !important;
    border-width: 1px;
    @include flex_box_vertical_center;
  }

  .image_container {
    display: grid;
  }

}

.block_type_button_container .MuiIconButton-root {

  .MuiSvgIcon-root {
    color: rgba(#000, 0.2);
    font-size: 26px;
  }

}

.MuiBreadcrumbs-ol .MuiBreadcrumbs-li {

  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }

  }
}

.MuiBreadcrumbs-ol .MuiBreadcrumbs-separator {
  color: $theme_color2;
}

.accordion_container_for_mobile .MuiPaper-root > .MuiExpansionPanelSummary-root {
  .MuiSvgIcon-root {
    @include square(1.3em);
    color: #000;
  }

}

.photo_modal {

  .header {
    height: 50px;
    @include flex_align(flex-end, center);
    padding-left: $spacing*4;
    padding-right: $spacing*4;
    background-color: #F4F6F8;
  }

}

.photo_modal .slide {
  @include flex_horizontal_and_vertical_center;


  img {
    max-width: 100%;
    max-height: 100%;
  }

}


.origin_code_table,
.analog_code_table,
.product_features_list {

  .MuiTableRow-root {

    &:nth-child(odd) {
      background: rgba(0, 0, 0, 0.04);
    }

  }

}


.main_search_container.MuiGrid-grid-xs-9 .search_result_horiz_prod_block {

  @include full_screen_column(1400px, 3);
  @include full_screen_column(1920px, 4);

}

.main_search_container.MuiGrid-grid-xs-12 .search_result_horiz_prod_block {
  @include full_screen_column(1400px, 4);
  @include full_screen_column(1920px, 5);
}

.product_page_container .analogs_list .search_result_vert_prod_block {
  height: auto;
}


@media (min-width: 1280px) {

  .product_page_container .features_block_container > .MuiGrid-root.MuiGrid-grid-md-12 {
    @include grid_column_size(3);
  }
  .break_world {
    display: none;
  }

}


.features_block_container .product_features_list {
  background: #F2F2F2;
  height: 100%;
  min-height: 30px;
  padding: 1px;
  padding-left: 0;
  padding-bottom: 2px;
  border-radius: 4px;

  .MuiListItem-root {
    padding-top: 0;
    padding-bottom: 0;
    @include align-items(center);
    height: 100%;
  }

  .MuiListItemText-root {
    margin: 0;
    padding-left: 4px;
  }


  .MuiListItemIcon-root {
    @include align-items(center);
    @include justify-content(center);
    min-width: 32px;
  }

  .MuiSvgIcon-root {
    color: $theme_color2;
    font-size: 2.3em;
    position: absolute;
    left: -11px;
  }


  .MuiTypography-body2 {
    display: block;
    line-height: 14px;
    word-wrap: break-word;
    font-size: 13px;
    color: #606060;
  }

  @include xlUp {
    .one_line {
      display: none;
    }
  }

  @media (min-width: 1280px) {

    .break_world {
      display: none;
    }
    .one_line {
      display: block;
    }

  }


}


.MuiPickersCalendarHeader-daysHeader .MuiTypography-caption {
  color: $theme_color2;

  &:nth-last-child(1),
  &:nth-last-child(2) {
    color: red;
  }
}

.table_order_inner_row {
  background-color: #fff;
}

.table_order_inner_row .article_brand a {
  color: $theme_color2;
  font-weight: 600;
}

.table_order_inner_row .MuiTableCell-root {
  border-color: rgba(0, 0, 0, 0.23);
}

.table_order_inner_row .article_brand {
  margin-bottom: $spacing;


}

.order_table_row {
  background-color: #fff;
  border-bottom: 1px solid $border_gray_color;

  .MuiTableCell-root {
    padding: $spacing $spacing*2;
    border-color: rgba(0, 0, 0, 0.23);
  }


  & + .MuiTableRow-root > .MuiTableCell-root {
    border-bottom: none;
  }

}

.orders_mobile_list_item {
  @include flex-wrap(wrap);
  padding: 6px;
  font-size: 14px;
  margin-bottom: 16px;
  background-color: #fff;

  .listItemHeader,
  .listItemFooter {
    width: 100%;
  }

  .listItemHeader {
    @include flex_align(space-between, flex-start);
    margin-bottom: 10px;
  }

  .listItemFooter {
    @include flex_align(space-between, flex-end);
  }

  .article_brand a {
    color: $theme_color2;
    font-weight: 600;
  }

}

.order_dialog .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #000;
  border-width: 1px;
}

.tree_catalog > .MuiListItem-root:not(.selectIdItem) {
  padding-left: $spacing*2;
}

.order_dialog .input_grid {
  padding-top: 0;
}

.order_dialog .MuiGrid-spacing-xs-2 > .MuiGrid-item {
  padding-top: 0;
  padding-bottom: 0;
}
.order_dialog .close .MuiSvgIcon-root {
  background-color: transparent;
}
.order_dialog .order_select_container {
  width: 100%;
  margin-top: $spacing;

  .MuiSelect-iconOutlined {
    top: 0;
    bottom: 0;
    margin: auto;
  }

}

.order_dialog .blStrData {
  @include flexbox;
  @include flex-wrap(wrap);
  text-align: center;
  @include justify-content(space-between);
}

.order_dialog .text_input_template {
  width: 100%;
  margin-top: $spacing;


  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-endAdornment {
    right: 7px;
  }


  .MuiOutlinedInput-multiline {
    padding-top: 11px;
    padding-bottom: 11px;
  }

}

.order_dialog .template_input {
  position: relative;
  border: 1px solid $border_gray_color2;
  cursor: pointer;
  @include flexbox;
  padding: 0 $spacing;
  @include flex-wrap(wrap);
  margin-top: 16px;
  min-height: 40px;
  text-align: left;
  border-radius: 5px;
  @include justify-content(space-between);

  .input_title {
    margin-top: -10px;
    background-color: #fff;
    padding: 2px 4px;
    border-radius: 5px;
    font-size: 12px;
  }

  .input_container {
    margin-top: -6px;
  }


  .arrow_container {

    & > div {
      width: 100%;
      color: rgb(119, 119, 119);
    }

    .MuiSvgIcon-root {
      position: absolute;
      right: 6px;
      top: 0;
      bottom: 0;
      margin: auto;
    }

  }

  .MuiTypography-colorTextSecondary {
    line-height: 25px;
  }

}

//.order_dialog .order_checkbox_container {
//  margin-top: $spacing;
//}


.order_dialog .order_checkbox {
  padding-top: 6px;
  padding-bottom: 6px;
}


.currency_select_list .MuiListItem-root {
  .MuiSvgIcon-root {
    font-size: 0.75rem;
  }
}