.guarantee {
  &-list {
    font-weight: bold;
    font-size: 16px;
    line-height: 120%;
    color: #1F1F1F;
    padding: 0;
    list-style-type: decimal;

    li {
      margin: 40px 0;
    }

    ol {
      padding: 0;
      margin: 20px 0;

      font-family: $font-meri;
      font-weight: 400;
      font-size: 16px;
      line-height: 160%;
      color: #414D5F;

      li {
        margin: 0;
      }
    }
  }

  &-item {
  }

  ol {
    counter-reset: item
  }

  li {
    display: block
  }

  li:before {
    content: counters(item, ".") ".";
    counter-increment: item
  }

  &-product {
    &__list {
      display: flex;
      flex-wrap: wrap;
      margin: 30px 0;
    }

    &__item {
      max-width: 288px;
      width: 100%;
      font-weight: 600;
      font-size: 16px;
      line-height: 140%;
      color: #1F1F1F;
      margin-bottom: 15px;

      &:not(:last-child) {
        margin-right: 15px;
      }

      .img {
        height: 191px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 24px;

        & img{
          position: relative;
          z-index: 1;
        }

        &::before {
          content: '';
          position: absolute;
          width: 175px;
          height: 175px;
          border-radius: 50%;
          z-index: -1;
          background-color: $yellow;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 0;
        }
      }

      .title {
        font-size: 16px;
        line-height: 140%;
      }

      .description {
        font-family: $font-meri;
        font-size: 16px;
        line-height: 140%;
        font-weight: bold;
        color: #999;
      }
    }
  }
}


@media screen and (max-width: 767px) {
  .guarantee-product__item {
    max-width: 100%;
  }

}
