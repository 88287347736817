.about {
  &-context {
    position: relative;
    // max-height: 390px;

    &__inner {
      height: 100%;
      // padding-bottom: 60px;
      box-sizing: border-box;
      // max-height: 390px;
      overflow-y: auto;
      padding-right: 15px;

      &::-webkit-scrollbar-track {
        background-color: $white;
      }

      &::-webkit-scrollbar {
        width: 2px;
        background-color: $white;
      }

      &::-webkit-scrollbar-thumb {
        width: 4px;
        background-color: $yellow;
      }
    }

    // &::before {
    //   content: '';
    //   position: absolute;
    //   width: 100%;
    //   height: 60px;
    //   bottom: 0;
    //   left: 0;
    //   background: linear-gradient(180deg, rgba(245, 247, 251, 0) 0%, #F5F7FB 100%);
    // }

    p {
      margin-bottom: 32px;
      font-size: 16px;
      line-height: 160%;
      color: #414D5F;

      &:nth-last-child(1) {
        margin-bottom: 0;
      }
    }


  }

  img {
    max-width: 100%;
  }

  &-us {
    &__description {
      font-size: 16px;
      line-height: 160%;
      color: #414D5F;

      strong,
      b {
        font-weight: 600;
      }
    }

    &__list {
      display: flex;
      flex-wrap: wrap;
      padding: 0;
      font-weight: bold;
      font-size: 16px;
      line-height: 120%;
    }

    &__item {
      display: flex;
      margin-right: 34px;
      margin-bottom: 10px;
      max-width: 325px;

      &:nth-last-child(1) {
        margin-right: 0;
      }

      .icon {
        width: 52px;
        height: 52px;
        background: $white;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        margin-right: 14px;
        transition: 0.3s;

        &:hover {
          border: 1px solid $blue;
        }
      }

      .content {
        width: calc(100% - 64px);
      }

      .title {
        font-size: 16px;
        line-height: 120%;

      }

      .description {
        font-size: 16px;
        line-height: 140%;
        color: #414D5F;
        margin: 0 0 10px;
      }

      ul {
        margin: 20px 0;
        padding: 0;

        li {
          margin: 15px 0;
        }
      }

    }

    &__title {
      font-size: 24px;
      line-height: 100%;
    }

    &__info {
      font-weight: 600;
      font-size: 16px;
      line-height: 160%;
    }

    &__footer {
      display: flex;
      flex-wrap: wrap;
    }

    &__img {
      max-width: 100%;
    }
  }
}

.delivery-list {
  .title {
    min-height: 50px;
    display: flex;
    align-items: center;
  }
}

.about-us-page {
  padding-top: 32px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 100px;

  @media(max-width: 991.98px) {
    gap: 50px;
  }

  @media(max-width: 479.98px) {
    gap: 32px;
  }

  p {
    color: $dashboard_color3;
    font-size: 14px;
    font-weight: 400;
  }

  figure {
    position: relative;
    background-color: #fff;
    box-shadow: 0px 12px 32px 0px rgba(51, 38, 174, 0.08);
    margin: 0;

    figcaption {
      padding: 32px 25px;
      background-color: $black;
      color: #fff;
      font-size: 14px;
      font-weight: 400;
    }
  }

  &__history {
    display: flex;
    align-items: center;
    gap: 32px;

    @media(max-width: 1023.98px) {
      flex-direction: column;
    }

    figure {
      max-width: 462px;
    }

    & .dashboard-card {
      padding: 32px;
      display: flex;
      column-gap: 25px;
      row-gap: 32px;
      align-items: flex-start;
      flex-wrap: wrap;
      min-width: 676px;

      @media(max-width: 1023.98px) {
        min-width: unset;
      }

      @media(max-width: 767.98px) {
        flex-direction: column;
      }

      @media(max-width: 476px) {
        padding: 16px;
      }
    }
  }



  &__card-history {
    width: calc(50% - 12.5px);

    @media(max-width: 767.98px) {
      width: 100%;
    }

    &_title {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 10px;
      padding-bottom: 8px;
      margin-bottom: 16px;
      border-bottom: 1px solid $yellow;

      h4 {
        font-size: 16px;
        font-weight: 700;
        color: $black;
      }

      span {
        display: block;
        background-color: $black;
        min-width: 16px;
        height: 16px;
        border-radius: 50%;
      }
    }
  }

  &__video-section {
    display: flex;
    align-items: center;
    gap: 32px;

    @media(max-width: 1023.98px) {
      flex-direction: column;
    }

    &_title {
      position: relative;
      max-width: 462px;
      text-align: center;
      color: $dashboard_color3;
      font-size: 20px;
      font-weight: 500;

      svg:first-child {
        position: absolute;
        top: -35px;
        left: -10px;
      }

      svg:last-child {
        position: absolute;
        bottom: -35px;
        right: -10px;
      }

      @media(max-width: 1023.98px) {
        max-width: 100%;
      }
    }

    &_wrapper {
      width: 100%;
      position: relative;
      padding-bottom: 35.6%;
      min-width: 676px;

      @media(max-width: 1023.98px) {
        min-width: unset;
        padding-bottom: 56.7%;
      }

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        border-radius: 20px;
      }
    }
  }

  &__advantages {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 25px;

    @media(max-width: 991.98px){
      grid-template-columns: repeat(2, 1fr);
    }

    @media(max-width: 767.98px){
      grid-template-columns: repeat(1, 1fr);
    }

    & .dashboard-card {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      padding: 24px;
      text-align: center;

      h4 {
        width: 100%;
        font-size: 24px;
        font-weight: 500;
        color: $black;
        padding-bottom: 16px;
        margin: 16px 0;
        border-bottom: 1px solid $yellow;
      }
    }

    &_wrapper {
      width: 100%;
      position: relative;
      padding-bottom: 100%;

      @media(max-width: 767.98px){
        padding-bottom: 57%;
      }

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }
}