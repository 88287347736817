@import "../../../../style/variables";
@import "../../../../style/mixins";

$vertical_miniature_list_width: $spacing*11;

.product-carousel {
  width: 100%;
  padding-bottom: $spacing*2;

  &,
  .slick-slider,
  .slick-track,
  .slick-list {
    height: 100%;
  }

  .slick-slide > * {
    height: 100%;
  }

  .slick-slider {
    margin-bottom: $spacing*2;
  }

  .slick-slide > * > * {
    background-color: #fff;
  }

  @include mdUp {

    & {
      padding-bottom: 0;
    }

    &.multiple .slick-slider {
      padding-bottom: $spacing*6 + $spacing*2;
    }
  }

  @include xlUp {
    &.multiple .slick-slider {
      padding-bottom: 0;
    }
  }


  .slick-slide > * > * {
    display: flex !important;
    @include align-items(center);
    @include justify-content(center);
    height: 100%;
  }

  .slick-list img {
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }

  .slick-dots li {
    @include slick_dots_li;
  }

  @include mdUp {

    &,
    .slick-slider,
    .slick-track,
    .slick-list {
      height: 100%;
    }

    .slick-dots {
      display: flex !important;
      @include justify-content(flex-start);
      bottom: 0;
    }

    .slick-dots li {
      margin: 0 $spacing;
      max-width: $vertical_miniature_list_width;
      width: $vertical_miniature_list_width;
      @include transition();
      background-color: #fff;
      @include flexbox;
      @include align-items(center);
      @include justify-content(center);
      padding: 2px;
      height: $spacing * 7;
      border-radius: 4px;
      border: 1px solid transparent;

      &:hover {
        border: 1px solid rgba(#aaaaaa, 0.4);
      }

      &.slick-active {
        border: 1px solid $theme_color2;
      }

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      & img {
        display: block;
        height: auto;
        width: auto;
        max-width: 100%;
        max-height: 100%;
      }

    }


  }

  @include lgUp {
    .slick-list img {
    }
  }

  @include xlUp {

    .slick-dots li {
      min-height: 0;
    }
  }


  .slick-prev,
  .slick-next {
    z-index: 1;
  }

  .slick-prev {
    left: $spacing;
  }

  .slick-next {
    right: $spacing;
  }

  .slick-prev:before,
  .slick-next:before {
    color: $theme_color1;
  }

  @include xlUp {

    &.multiple {

      .slick-list {
        width: calc(100% - (#{$vertical_miniature_list_width} + #{$spacing*2}));
        margin-left: $vertical_miniature_list_width + $spacing*2;
      }

      .slick-dots {
        width: $vertical_miniature_list_width;
        height: 100%;
        @include flex-direction(column);

        li {
          margin-left: 0;
          margin-right: 0;
          margin-bottom: $spacing;
        }
      }
    }

  }

}