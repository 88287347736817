.user {
  &-main {
    position: relative;
    background-image: url("../../images/auto-bg.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 100vh;
    overflow-y: auto;

    &__wrapper {
      max-width: 1240px;
      width: 100%;
      margin: 0 auto;
      padding: 0 10px;
      box-sizing: border-box;
      height: inherit;
    }

    &__inner {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: inherit;
      padding-bottom: 75px;
      box-sizing: border-box;

    }

    &__left {
      max-width: 388px;
      width: 100%;

      &-logo {
        max-width: 100%;
        margin-bottom: 13px;
      }

      &-text {
        font-weight: 600;
        font-size: 14px;
        line-height: 180%;
        text-transform: uppercase;
        color: rgba($white, 0.7);
      }
    }

    &__right {
      max-width: 385px;
      width: 100%;
    }
  }

  &-form {
    max-width: 385px;
    width: 100%;
    padding: 40px 40px 0;
    background: $white;
    box-shadow: 0 5px 6px rgba(0, 0, 0, 0.02);
    border-radius: 4px;

    &-login,
    &-registration {
      min-height: 500px;
    }

    .form__field-input {
      background: #EBF0F8;
      font-weight: 600;
      border-color: #EBF0F8;
      color: #1F1F1F;
    }
    .form__field-textarea {
      background: #EBF0F8;
      font-weight: 600;
      border-color: #EBF0F8;
      color: #1F1F1F;
    }

    .form__field-label {
      font-weight: 600;
      color: #1F1F1F;
    }

    .form__field {
      margin-bottom: 12px;

      &.focused,
      &.not-empty {
        .form__field-label {
          color: $blue;
        }
      }
    }

    .checkbox {
      justify-content: center;
    }

    .checkbox__marker {
      width: 14px;
      height: 14px;
      margin-right: 8px;
    }

    .checkbox__text {
      font-weight: 500;
      font-size: 11px;
      line-height: 160%;
      color: #414D5F;

      a {
        color: $yellow;
        text-decoration: none;
      }
    }

    &__link {
      display: block;
      font-weight: 600;
      font-size: 13px;
      line-height: 120%;
      text-decoration-line: underline;
      color: #414D5F;
      text-align: center;
      transition: 0.3s;

      &:hover {
        text-decoration-line: unset;
      }
    }

    &__footer {
      //background: #F5F7FB;
      box-shadow: 0 5px 6px rgba(0, 0, 0, 0.02);
      border-radius: 4px;
      text-align: center;
      font-weight: 600;
      font-size: 13px;
      line-height: 120%;
      color: #414D5F;

      &-button {
        font-family: $font-meri;
        padding: 15px;
        border: none;
        background: transparent;
        outline: none;
        font-weight: 600;
        font-size: 13px;
        line-height: 120%;
        color: #414D5F;
        display: flex;
        align-items: center;
        margin: 0 auto;
      }

      &-google {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 36px;
        height: 36px;
        background: linear-gradient(91.69deg, #D95433 0%, #EF7A5D 100.9%);
        border-radius: 50%;
        margin-left: 10px;
      }
    }

    &__title {
      font-size: 18px;
      line-height: 100%;
    }

    &__description {
      font-family: $font-meri;
      font-size: 16px;
      line-height: 140%;
      color: #414D5F;
      margin-right: 15px;
    }

    &__flex {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__icon {
      margin-right: 25px;
    }

    &__captcha {
      img {
        max-width: 100%;
      }
    }
  }

  &-tabs {
    font-size: 18px;
    line-height: 100%;
  }

  &-footer {
    position: absolute;
    bottom: 45px;
    left: 0;
    width: 100%;

    &__inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: 600;
      font-size: 13px;
      line-height: 120%;
      color: #BCBCBC;
    }

    &__agreement {
      transition: 0.3s;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.lang {
  &-list {
    display: flex;
  }

  &-item {
    margin-right: 20px;

    &:nth-last-child(1) {
      margin-right: 0;
    }

    &__active {
      color: $white;
    }
  }

  &-link {
  }
}

.auto-page {
  .progress-wrap {
    display: none !important;
  }
}

@include media-breakpoint-down(md) {
  .user-main__inner {
    flex-direction: column;
    justify-content: space-around;
  }
  .user-main__left-logo{
    max-width: 200px;
  }
  .user-main__left {
    padding: 20px 0;
  }
}

@include media-breakpoint-down(sm) {
  .user-main__left-logo {
    max-width: 250px;
  }
  .tabs {
    font-size: 16px;
  }
}
@media screen and (max-width: 353px) {
  .tabs li {
    margin-right: 10px;
  }
}
@media screen and (max-width: 410px) {
  .user-form {
    padding: 20px 20px 0;
  }
}