.list {
  padding: 0;
  font-family: $font-meri;
  font-size: 16px;
  line-height: 140%;

  li {
    position: relative;
    padding-left: 18px;
    margin-bottom: 8px;

    &::before {
      content: '';
      position: absolute;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: $yellow;
      left: 0;
      top: 7px;
    }
    b{
      font-weight: 500;
    }
  }

  &.phone {
    li {
      padding-left: 0;
      font-weight: 500;

      &::before {
        content: unset;
      }
    }
    a {
      position: relative;
      padding-left: 18px;
      display: flex;
      align-items: center;
      svg{
        margin-left: 6px;
      }

      &::before {
        content: '';
        position: absolute;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        // background: url("../img/svg/phone.svg");
        left: 0;
        top: 7px;
      }
    }
  }


  &.map {
    li {
      &::before {
        width: 10px;
        height: 13px;
        // background: url("../img/svg/map.svg");
      }
    }
  }

}