.banners {
  margin: 20px 0;

  &-inner {
    margin-top: 30px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 20px;

    .banner:last-child:not(:nth-child(even)) {
      @media screen and (min-width: 768px) {
        grid-column: span 2;
        grid-row: span 2;
      }

    }

    &_1 {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 21px;
    }
  }

  //.product__item-info{
  //  display: none;
  //}
  .product__list-wrapper {
    max-width: 896px;
    //width: 896px;
    width: 100%;
    order: 0;
    flex: 0 1 auto;
    align-self: center;
  }
}

.banner {
  color: $white;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;
  width: 100%;

  &.home {
    min-height: 170px;
    align-items: center;
    flex-direction: row;

    @media screen and(max-width: 520px) {
      min-height: 110px;
    }
  }

  &-img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    overflow: hidden;
    border-radius: 4px;
    z-index: -1;

    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba($black, 0.65);
    }

    img {
      width: 100%;
      height: 100%;
      // object-fit: cover;
    }
  }

  &-icon {
    width: 90px;
    height: 90px;
    border: 2px solid rgba(255, 255, 255, 0.25);
    box-sizing: border-box;
    border-radius: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
  }

  &-title {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 24px;
    line-height: 100%;
    margin-bottom: 6px;
    stroke: $white;
    transition: all ease 0.3s;

    &:hover {
      stroke: $yellow;
      color: $yellow;

    }

    svg {
      margin-left: 12px;
    }
  }

  &-description {
    opacity: 0.8;
    font-size: 15px;
    line-height: 140%;
  }

  &-list {
    width: 100%;
    margin-top: 20px;
    padding-left: 0;
    display: grid;
    grid-template-columns: repeat(2, 270px);
    grid-gap: 15px 55px;

    li {
      position: relative;
      padding-left: 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &::before {
        content: "";
        position: absolute;
        font-weight: bold;
        display: inline-block;
        width: 5px;
        height: 5px;
        background-color: rgba($white, 0.4);
        border-radius: 50%;
        left: 0;
        top: 9px;
      }

      span {
        width: 35px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 14px;
        background: rgba($white, 0.8);
        border-radius: 50px;

        font-weight: 600;
        font-size: 13px;
        line-height: 120%;
        text-align: center;
        color: #1F1F1F;
      }
    }
  }
}

.cashback-banners {
  .cashback-banner {
    img {
      width: 100%;
    }
  }
}

.аdvertising-banner {
  width: 100%;
  margin-bottom: 15px;
  //background-color: $blue;
  //border-radius: 4px;
  //color: $white;
  //height: fit-content;
  //
  //&_num {
  //  font-weight: bold;
  //  font-size: 20px;
  //  line-height: 100%;
  //  display: block;
  //  margin-right: 9px;
  //}
  //
  //&_text {
  //  font-family: $font-meri;
  //  font-size: 15px;
  //  line-height: 120%;
  //  display: flex;
  //
  //  &1 {
  //    font-weight: 600;
  //    font-size: 15px;
  //    line-height: 120%;
  //  }
  //}

  img {
    width: 100%;
    // object-fit: cover;
  }
}

@media screen and (max-width: 1500px) {
  .banners-inner_1 {
    display: block;
    flex-wrap: wrap;
  }

  .banners .product__list-wrapper {
    max-width: 100%;
  }
}

.banner-img {
  width: 100% !important;
}

@media screen and (max-width: 900px) {
  .banners .product__list-wrapper {
    width: 100%;
    max-width: 100vw;
  }

  .banners-inner {
    grid-template-columns: 1fr;
    flex-direction: column;
  }

  .banner {
    padding: 10px;

    &-img {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      overflow: hidden;
      border-radius: 4px;
      z-index: -1;

      &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba($black, 0.65);
      }

      img {
        width: 100%;
        height: 100%;
        // object-fit: cover;
      }
    }

    &-icon {
      width: 72px;
      height: 72px;
      margin-right: 10px;

      svg {
        width: 60%;
      }
    }

    &-title {
      font-size: 20px;
    }

    &-description {
      font-size: 13px;
    }
  }
}

@media screen and (max-width: 767px) {
  .banner-list {
    grid-template-columns: 1fr;
  }
}