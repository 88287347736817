.home-slider {
  color: $white;
  position: relative;
  overflow: hidden;

  &__item {
    position: relative;
    width: '100%';
    min-height: '200px';
    max-height:'390px';
    // height: 390px !important;

     .home__slider-image {
      object-fit: cover;
      // position: absolute;
      width: 100%;
      height: 100%;
      z-index: -1;
      left: 0;
      top: 0;
    }

    .wrapper-inner {
      margin-right: 67px;
    }
  }

  &__title {
    font-weight: bold;
    font-size: 60px;
    line-height: 120%;
    padding-top: 60px;

    span {
      color: $yellow;
    }
  }

  &__description {
    font-weight: 600;
    font-size: 22px;
    line-height: 160%;
    margin: 20px 0 30px;
  }

  &-nav {
    right: 46px;
    top: 46%;
    transform: translateY(-50%);
    position: absolute;
    height: 168px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 3;
    .home-slider-prev,
    .home-slider-next {
      cursor: pointer;
      width: 44px;
      height: 44px;
      border-radius: 50%;
      background-color: $yellow;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.3s ease;
      box-shadow: 5px 5px 9px rgba(0, 0, 0, 0.1);

      &:hover{
        background-color: $white;
      }
    }
    .info{
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      font-size: 14px;
      line-height: 140%;
      color: #DADADA;
      text-shadow: 1px 1px 0px #000;
    }
  }
}

.slick-vertical .slick-slide {
  border: none;
}

@media screen and (max-width: 1280px) {
  .home-slider {
    &__title {
      font-size: 48px;
    }

    &__description {
      font-size: 18px;
    }

    &__item .wrapper-inner {
      margin-right: 42px;
    }

    &-nav {
      right: 25px;
    }
  }
}

@media screen and (max-width: 1023px) {
  .home-slider {
    &__title {
      font-size: 36px;
    }

    &__item .wrapper-inner {
      margin-right: 0;
    }
  }
}

@media (max-width: 767px) {
  .home-slider {
    &__title {
      padding-top: 32px;
      font-size: 30px;
    }

    .btn-home-slider {
      max-width: 100%;
      font-size: 16px;
      font-weight: 600;
      height: 40px;
      // width: 215px;
    }

    // height: calc(100vh - 50px);
    max-height: 100%;

    .home-slider__item {
      // height: calc(100vh - 50px) !important;
    }

    .home-slider-nav {
      right: auto;
      left: 50%;
      transform: translateX(-50%);
      top: auto;
      bottom: 10px;
      flex-direction: row;
      width: 130px;
      height: 30px;
    }

    .home-slider-prev,
    .home-slider-next {
      transform: rotate(-90deg);
      max-height: 30px;
      max-width: 30px;
      width: 100%;
    }
  }
}
.home-slider-nav-horizontal {
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 3;
  bottom: 15px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  top: auto;
  flex-direction: row;
  width: calc(100% - 30px);
  .info {
    display: none;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: #DADADA;
    text-shadow: 1px 1px 0px #000;
  }
    .home-slider-prev,
    .home-slider-next {
      cursor: pointer;
      width: 44px;
      height: 44px;
      border-radius: 50%;
      background-color: $yellow;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.3s ease;
      box-shadow: 5px 5px 9px rgba(0, 0, 0, 0.1);

      &:hover{
        background-color: $white;
      }
      transform: rotate(-90deg);
      max-height: 30px;
      max-width: 30px;
      width: 100%;
    }}