.footer {
  background-color: $black;

  &__top {
    padding: 50px 0;
  }

  &__top-inner {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__logo {
    display: block;
    width: 160px;
    position: relative;
    top: -10px;
    margin-right: 60px;

    img {
      display: block;
      width: 100%;
    }
  }

  &__block {
    max-width: 130px;
  }

  &__navs {
    display: flex;

    &-item {
    }

    &-title {
      font-weight: 600;
      font-size: 18px;
      line-height: 160%;
      color: $white;
      margin-bottom: 15px;
    }
  }

  &__nav {
    margin-right: 60px;
    padding: 0;

    li {
      margin-bottom: 15px;
      transition: color 0.3s;
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 120%;
      color: #BCBCBC;
      fill: #BCBCBC;
      display: flex;
      align-items: center;

      svg {
        width: 20px;

        path {
          fill: #BCBCBC;
        }

        &.viber {
          margin-right: 0;
          margin-left: 8px;
        }
      }

      &:hover {
        color: $yellow;
        svg path {
          fill: $yellow;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    a {
      display: flex;
      align-items: center;

      svg {
        margin-right: 8px;

        &.viber {
          margin-right: 0;
          margin-left: 8px;
        }
      }
    }

  }

  &__copyright {
    font-weight: bold;
    font-size: 13px;
    line-height: 130%;
    color: #C5C2CF;
  }

  .langs {
    &__item-link {
      color: #C5C2CF;
    }

    &__sublist {
      top: auto;
      bottom: calc(100% + 10px);

      &::before {
        top: auto;
        bottom: -12px;
      }
    }
  }

  &__fb-link {
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 13px;
    line-height: 130%;
    color: #C5C2CF;
    margin-bottom: 35px;

    svg {
      margin-right: 10px;
    }
  }

  &__payments {
    display: flex;
    flex-wrap: wrap;
    max-width: 331px;
    flex-shrink: 0;
    margin-bottom: -20px;

    li {
      margin-right: 20px;
      margin-bottom: 20px;

      &:nth-child(4n) {
        margin-right: 0;
      }
    }

    a {
      display: block;
    }
  }
}
@media screen and (max-width: 1600px){
  .footer__top-inner{
    display: grid;
    grid-template-columns: repeat(4,1fr);
    grid-gap: 20px;
    .footer__logo{
      grid-column: 1/5;
    }
  }
}

@media screen and (max-width: 1200px){
  .footer__top-inner{
    grid-template-columns: repeat(2,1fr);
    .footer__logo{
      grid-column: 1/3;
    }
  }
}

@media screen and (max-width: 767px){
  .footer__top-inner{
    grid-template-columns: 1fr;
    .footer__logo{
      grid-column: 1/2;
    }
  }
}

@include media-breakpoint-down(xl) {
  .footer {
    &__bot {
      border-top: 1px solid rgba(255, 255, 255, 0.1);
      padding-top: 20px;
      padding-bottom: 20px;
      text-align: center;
    }
  }
}
