
*:focus {
  outline: none;
}


body {
  margin: 0;
  font-family: -apple-system, "Montserrat", sans-serif;
 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


img {
  max-width: 100%;

  &:focus {
    outline: none;
  }
}

a {
  text-decoration: none;
}

a:focus {
  outline: none;
}

body * {
  @include box_sizing(border-box);
}

body .root {
  background-color: #F4F6FA;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

#progressload { /*переделать*/
  position: absolute;
  top: 50%;
  left: 50%;
}
