.filter {
  &-title {
    font-weight: 600;
    font-size: 14px;
    line-height: 160%;
  }

  &-block {
    padding: 20px;
    width: 100%;
    box-sizing: border-box;
    background-color: $white;
    margin-bottom: 15px;
  }

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 7px;
  }

  &__search {
    position: relative;
    width: 100%;
    flex-shrink: 0;
    margin-right: 20px;

    &-input {
      width: 100%;
      background: #EBF0F8;
      border: 1px solid #EBF0F8;
      border-radius: 20px;
      padding: 0 50px 0 20px;
      height: 40px;
      line-height: 38px;
      font-weight: 600;
      font-size: 13px;
      font-family: $font-meri;
      color: $yellow;
      transition: 0.3s;

      &::placeholder {
        color: $grey;
      }

      &:hover {
        border: 1px solid $yellow;
      }

      &:focus {
        outline: none;
        border: 1px solid $yellow;
      }
    }

    &-btn {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 15px;
      border: none;
      background-color: transparent;
      stroke: $grey;
      transition: 0.3s;

      &:hover {
        stroke: $yellow;
      }

      &:focus {
        outline: none;
      }
    }
  }

  &-category {
    &__item {
      svg {
        stroke: #999;
        cursor: pointer;
      }

      &.active {
        .filter-category__header .checkbox__text {
          color: $blue;
        }

        .filter-category__body {
          display: block;
        }

        svg {
          transform: rotate(180deg);
          stroke: $blue;
        }
      }
    }

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__body {
      display: none;
      margin-top: 15px;
      margin-left: 30px;
    }

  }

  .btn-filter {
    background-color: $white;
    border-radius: 4px;
    color: #414D5F;
    font-weight: 500;
    padding: 6px 10px;
    font-size: 13px;
    line-height: 100%;
    letter-spacing: 1.2px;

    svg {
      margin-right: 7px;
    }
  }

  .btn-more {
    border-radius: 4px;
    font-weight: 500;
    padding: 6px 10px;
    font-size: 13px;
    line-height: 100%;
    letter-spacing: 1.2px;
    background: #F5F7FB;
    color: #777777;

    svg {
      margin-left: 5px;
    }
  }


}