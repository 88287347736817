// GLOBAL STYLES
@import "global/custom-variables";

* {
  font-family: $font-meri !important;
}

input {
  font-family: $font-meri;
}

@import "global/functions";
@import "global/bootstrap-variables";
@import "global/mixins";
@import "global/reboot";
@import "global/type";
@import "global/grid";
@import "global/forms";
@import "global/buttons";
@import "global/utilities";
@import "global/fonts";
@import "global/tabs";
@import "global/list";

// PLUGINS STYLE
@import "plugins/jquery.rateyo.scss";
@import "plugins/slick.scss";
@import "plugins/swiper";
//@import "plugins/aos.scss";
//@import "plugins/fancybox.scss";
@import "plugins/select2.scss";

// BLOCKS STYLE
//@import "blocks/popup-block";
@import "blocks/page-header";
@import "blocks/page-footer";
@import "blocks/langs";
@import "blocks/home-slider";
@import "blocks/banners";
@import "blocks/promotion";
@import "blocks/product";
@import "blocks/about";
@import "blocks/contact";
@import "blocks/breadcrumbs";
@import "blocks/filter";
@import "blocks/listing";
@import "blocks/single-product";
@import "blocks/tabs";
@import "blocks/simple-rating";
@import "blocks/review";
@import "blocks/modal";
@import "blocks/table";
@import "blocks/basket";
@import "blocks/auto-pages";
@import "blocks/category";
@import "blocks/delivery";
@import "blocks/slots";
@import "blocks/guarantee";
@import "blocks/page-dashboard";

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap');

html {
  scroll-behavior: smooth;
}

body {
  position: relative;
  background: #F5F7FB;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: $black;
  font-weight: 400;
  line-height: 1.2;
  font-size: 16px;
  min-width: 320px;
  font-family: $font-meri !important;

  &.no-scroll {
    overflow: hidden;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin-top: 66px;
  overflow: hidden;
  main {
    flex: 1;
  }

  &-inner {
    padding: 0 45px;
    box-sizing: border-box;
  }
}

.progress-wrap {
  position: fixed;
  right: 50px;
  bottom: 50px;
  height: 46px;
  width: 46px;
  cursor: pointer;
  display: block;
  border-radius: 50px;
  box-shadow: 0 6px 18px rgba(49, 94, 251, 0.22);
  z-index: 10000;
  opacity: 0;
  visibility: hidden;
  transform: translateY(15px);
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  background-color: $white;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 12px;
    height: 12px;
    background-image: url(../images/chevron-up-main.svg);
    background-repeat: no-repeat;
    background-position: center;
  }

  &.active-progress {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }

  &:hover::before {
    opacity: 1;
  }

  svg {
    path {
      fill: none;
    }

    &.progress-circle path {
      stroke: $yellow;
      stroke-width: 4;
      box-sizing: border-box;
      -webkit-transition: all 200ms linear;
      transition: all 200ms linear;
    }
  }

}
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 4px solid #FFBE00;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #FFBE00 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.products-block {
  padding: 20px 0;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__slick {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-bottom: 10px;
    gap: 20px;
    white-space: nowrap;

    @media screen and (max-width: 450px) {
      gap: 10px;
    }

    .info {
      font-weight: 500;
      font-size: 12px;
      line-height: 140%;
      text-align: center;
      color: #777777;
      width: fit-content;
    }

    // .prev {
    //   margin-right: 20px;
    // }

    // .next {
    //   margin-left: 20px;
    // }
    
    
    .prev, .next {
      cursor: pointer;
      width: 36px;
      height: 36px;
      border-radius: 50%;
      background-color: $white;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.3s ease;
      @media screen and (max-width: 450px) {
        width: 29px;
        height: 29px;
      }

      &:hover {
        background-color: $yellow;
      }
    }
  }
}

.select-popover .MuiPopover-paper {
  top: 55px !important;
}
.jq-ry-container {
  padding: 0;
}
.product-skeleton-wrapper{
  // display: flex;
  // justify-content: space-between;
  // width: 100%;
  // height: 450px;
  // gap: 10px;
  margin-top: 48px;

  .swiper-slide {
    height: 100%;
  }
}
.items-center {
  align-items: center;
}
.items-end {
  align-items: end;
}
.justify-between {
  justify-content: space-between;
}
.gap-10 {
  gap: 10px;
}
.header__column {
  position: static;
}
.addtocart__promo {
  background: #315EFB;
  border-radius: 4px;
  position: relative;
  padding: 10px;
  display: flex;
  gap: 10px;
  
  .promo-iamge {
    position: absolute;
    bottom: 0;
    right: 0px;
    width: 50px;
  }

  .promo-title  {
    p {
      color: #fff !important;
    }
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.home-products .swiper-wrapper {
  @media screen and(max-width: 450px) {
    height: auto;
  }
}

.map__btn {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 1000;
}
.jss49 {
  max-width: 100%;
  border-radius: 0;
}
// .banners-inner_1 {
//   min-height: 560px;
// }

.packing_container, .ball_icon, .bob_icon {
  text-align: center;
}

.packing_container .MuiSvgIcon-root {
  font-size: 300px;
  max-width: 100%;
  width: 300px;
  height: 300px;
}

.ball_icon .MuiSvgIcon-root {
  font-size: 9rem;
  max-width: 100%;
}

.bob_icon .MuiSvgIcon-root {
  font-size: 6rem;
  max-width: 100%;
}
.flex-column {
  flex-direction: column;
}
.product-empty {
  opacity: 0.3 !important;
  pointer-events: none !important;
}
.disabled {
  opacity: 0.5;
  pointer-events: none;
}
.cursor-pointer {
  cursor: pointer;
}
.justify-center {
  justify-content: center;
}
.justify-end {
  justify-content: end;
}
.random-products-slider {
  .swiper-wrapper{
    height: 97%;
  }
}

.order-item {
  svg {
    opacity: 0.3;
  }
  &:hover {
    svg {
      opacity: 1;
    }
  }
}
.promotion-page-left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.lists-wrapper {
  display: flex;
  gap: 10px;
  @media (max-width: 580px) {
    flex-direction: column;
  }
}

.formated-text-wrapper {
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    color: #414D5F;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }
  li{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #1F1F1F;

    &::marker {
      color: #FFBE00;
    }
  }
  ul {
    list-style:disc;
  }
  ol {
    list-style: decimal;

    li::marker {
      font-weight: 700;
    }
  }
  blockquote {
    background: #f9f9f9;
    margin: 1.5em 10px;
    padding: 0.5em 10px;
    quotes: "\201C""\201D""\2018""\2019";
    padding: 25px 25px 25px 55px;
    position: relative;

    p {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      color: #1F1F1F;
    }

    &:before {
      color: #FFBE00;
      content: open-quote;
      font-size: 4em;
      line-height: 0.1em;
      margin-right: 0.25em;
      vertical-align: -0.4em;
      position: absolute;
      top: 25%;
      left: 20px;
      transform: translateY(-50%);
    }
  }

  h1,h2,h3,h4,h5,h6 {
    font-style: normal;
    font-weight: 700;
    line-height: 160%;
    color: #414D5F;
    margin: 25px 0;
    
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }

  h1 {
    font-size: 26px;
  }
  h2 {
    font-size: 24px;
  }
  h3 {
    font-size: 22px;
  }
  h4 {
    font-size: 20px;
  }
  h5 {
    font-size: 18px;
  }
  h6 {
    font-size: 16px;
  }

  a {
    color: #FFBE00;
  }
}
.stars {
  .star-ratings {
    display: flex !important;
    flex-wrap: nowrap !important;
  }
}
.tabs {
  &::-webkit-scrollbar {
    display: none;
  }
}
.banner-skelton-wrapper {
  @media screen and(max-width: 520px) {
    height: 100px !important;
  }
}
.user-form__captcha.rc-anchor-normal {
  width: 250px;
}
@include media-breakpoint-down(sm) {
  .progress-wrap {
    position: fixed;
    right: 15px;
    bottom: 15px;
  }
}
@media screen and (max-width: 1600px) {
  .toggle-text {
    display: none;
  }
}
@media screen and (max-width: 1500px) {
  .wrapper-inner {
    padding: 0 25px;
  }
}

@media screen and (max-width: 1200px) {
  .wrapper-inner {
    padding: 0 15px;
  }
}

@media screen and (max-width: 767px) {
  .wrapper-inner {
    padding: 0 10px;
  }
  .screen {
    display: block !important;
  }
}

@media screen and (max-width: 350px) {
  .wrapper-inner {
    padding: 0 ;
  }
}

@media screen and (max-width: 740px) {
  .select__language-mobile, .header__word-mobile, .favourite__icon {
    display: none;
  }
  .svg-group {
    display: flex;
    align-items: center;
    svg {
      margin-right: 0;
    }
  }
  .wrapper {
    margin-top: 50px;

    main {
      height: auto;
    }
  }
}

.dots-loader{
  display: flex;
  align-items: center;

  span{
    border-radius: 50%;
  }

  &>span:nth-child(1){
    animation: dots-loader 1s -0.3s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08)
  }
  &>span:nth-child(2){
    animation: dots-loader 1s -0.2s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08)
  }
  &>span:nth-child(3){
    animation: dots-loader 1s -0.1s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08)
  }
}

@keyframes dots-loader {
  0%, 100% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(0.4);
    opacity: 0.1;
  }
}

// @media screen and (max-width: 450px) {
//   .products-mobile .swiper-wrapper {
//     height: 310px !important;
//   }
//   .title {
//     font-size: 20px;
//   }
// }