.dashboard {
    scroll-behavior: smooth;

    &__title {
        color: $dashboard_color1;
        font-size: 24px;
        font-weight: 500;
    }

    &__user {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 12px;
        border-bottom: 1px solid $dashboard_line;

        &_icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 92px;
            height: 92px;
            border-radius: 50%;
            background-color: $dashboard_color5;
        }

        &_information {
            display: flex;
            flex-direction: column;
            gap: 5px;
            padding-top: 15px;
            text-align: center;

            h4 {
                font-size: 14px;
                font-weight: 700;
                color: $dashboard_color3;
            }

            p {
                font-size: 12px;
                color: $dashboard_color4;
            }
        }
    }

    &__user-data {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        gap: 16px;
        padding-top: 20px;

        &_card {
            width: calc(50% - 8px);
            display: flex;
            flex-direction: column;
            gap: 15px;

            @media(max-width: 560px) {
                width: 100%;
            }
        }

        &_item {
            display: flex;
            align-items: flex-start;
            gap: 15px;
        }

        &_data {
            display: flex;
            flex-direction: column;
            gap: 5px;

            @media(max-width: 560px) {
                width: 100%;
                flex-direction: row;
                justify-content: space-between;
                gap: 15px;
            }

            @media(max-width: 476px) {
                width: 100%;
                flex-direction: column;
                justify-content: space-between;
                gap: 5px;
            }

            h4 {
                font-size: 14px;
                font-weight: 600;
                color: $dashboard_color3;
            }

            p {
                font-size: 14px;
                font-weight: 400;
                color: $dashboard_color4;
            }
        }
    }
}

.dashboard-card {
    position: relative;
    display: block;
    width: 100%;
    padding: 0 32px;
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0px 12px 32px 0px rgba(51, 38, 174, 0.08);
    overflow: hidden;

    @media(max-width: 476px) {
        padding: 0 16px;
    }

    .swiper-pagination {
        padding: 34px 0 0 0;
        justify-content: center;

        .swiper-pagination-bullet {
            border-radius: 50%;
            max-width: 14px;
            height: 14px;

            &-active {
                max-width: 18px;
                height: 18px;
            }
        }
    }
}

.dashboard-animate {
    width: 100%;
    overflow: hidden;
    opacity: 0;
    transform: scale(0.85);
    transition: 1s;

    &.scroll-effect {
        opacity: 1;
        transform: scale(1) !important;
    }
}

.dashboard-card-empty {
    width: 100%;
    height: 100%;
    padding: 15px 15%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    backdrop-filter: blur(8px);
    font-weight: 700;
    font-size: 18px;
    color: $dashboard_color1;
    text-align: center;
    border-radius: 12px;
    
    @media(max-width: 479px) {
        padding: 15px 10%;
        font-size: 14px;
    }
}

.dashboard-title {
    font-size: 20px;
    font-weight: 500;
    color: $dashboard_color3;
}

.link-to {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    a {
        display: inline-flex;
        align-items: center;
        gap: 12px;
        padding: 8px 16px;
        border: 1px solid $dashboard_link;
        border-radius: 20px;
        font-size: 14px;
        font-weight: 400;
        color: $dashboard_link;
        transition: 0.3s;

        &:hover {
            color: $blue;
            border: 1px solid $blue;

            svg {
                stroke: $blue;
            }
        }

        span {
            @media(max-width: 560px) {
                display: none;
            }
        }

        svg {
            stroke: $dashboard_link;
            transition: 0.3s;
        }
    }
}

.dashboard-table {
    width: 100%;

    thead {
        background-color: $dashboard_color6;
        max-height: 48px;

        th {
            padding: 13px 16px;
            color: $dashboard_color4;
            white-space: nowrap;
            font-size: 14px;
            font-weight: 600;
            border: none;
            text-align: center;

            &:first-child {
                text-align: left;
                border-radius: 12px 0 0 0;
            }

            &:last-child {
                text-align: right;
                border-radius: 0 12px 0 0;
            }
        }
    }

    tbody {
        border-top: none;
        border-bottom: 1px solid $dashboard_line;
        max-height: 46px;

        &:last-child {
            border-bottom: none;
        }

        td {
            padding: 12px 16px;
            color: $dashboard_color4;
            font-size: 13px;
            font-weight: 600;
            border: none;
            text-align: center;

            &:first-child {
                text-align: left;
            }

            &:last-child {
                text-align: right;
            }
        }
    }
}

.dashboard-text {
    font-size: 14px;
    font-weight: 500;
    color: $dashboard_color4;
}

.dashboard-form {
    display: grid;
    gap: 16px;
    max-width: 832px;
    margin: 0 auto;

    @media(max-width: 767px) {
        min-width: 100%;
    }

    &__body {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 15px;

        @media(max-width: 767px) {
            flex-direction: column;
        }
    }

    &__text {
        color: $dashboard_color2;
    }

    &__form {
        display: grid;
        gap: 16px;

        div {
            display: grid;
            gap: 4px;
        }
    }

    &__checkbox-wrapper {
        a {
            margin-left: 3px;
        }
    }

    label {
        color: $dashboard_color3;
        font-size: 13px;
        font-weight: 400;
        margin: 0;

        span:nth-child(2) {
            color: $dashboard_color3;
            font-size: 13px;
            font-weight: 400;
        }
    }

    a {
        position: relative;
        font-size: 13px;
        color: #2F80ED;
        transition: 0.3s;

        &:hover {
            text-decoration: underline;
        }
    }

    input {
        width: 100%;
        height: 42px;
        padding: 0 10px;
        box-sizing: border-box;
        border: 0.8px solid $dashboard_color4;
        border-radius: 4px;
        font-size: 14px;
        font-weight: 600;
        color: $dashboard_color1;

        &[type=number]::-webkit-inner-spin-button,
        &[type=number]::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        &::placeholder {
            font-size: 13px;
            font-weight: 400;
            color: $dashboard_color4;
        }
    }

    textarea {
        resize: none;
        width: 100%;
        height: 70px;
        padding: 10px;
        box-sizing: border-box;
        border: 0.8px solid $dashboard_color4;
        border-radius: 4px;
        font-size: 14px;
        font-weight: 600;
        color: $dashboard_color1;
        transition: 0.5s;

        &::placeholder {
            font-size: 13px;
            font-weight: 400;
            color: $dashboard_color4;
        }
    }

    &__error {
        font-size: 13px;
        color: $dashboard_red;
        transition: 0.5s;
        max-height: 0;
        opacity: 0;

        &.active {
            opacity: 1;
            max-height: 100%;
        }
    }
}

.dashboard-status {
    position: absolute;
    top: 12px;
    left: 20px;
    display: grid;
    gap: 10px;

    @media(max-width: 476px) {
        left: 12px;
    }

    &__item {
        display: inline-flex;
        align-items: center;
        gap: 10px;
        padding: 6px 10px;
        border-radius: 10px;
        font-size: 12px;

        @media(max-width: 476px) {
            font-size: 10px;
        }
    }
}

.instruction-btn {
    position: relative;
    min-width: 164px;
    height: 42px;
    border-radius: 90px;
    border: 1px solid $yellow;
    overflow: hidden;

    &::before {
        content: "";
        position: absolute;
        left: -60%;
        top: 0;
        width: 100%;
        height: 100%;
        border-radius: 90px;
        background-color: $yellow;
        opacity: 0;
        transition: 1s;
    }

    span {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: $dark_yellow;
        transition: 0.7s;
    }

    span:nth-child(2) {
        opacity: 0;
        color: $black;
        transition: 0.7s;
    }

    &:hover {
        span:nth-child(1) {
            opacity: 0;
        }

        span:nth-child(2) {
            opacity: 1;
        }

        &::before {
            left: 0;
            opacity: 1;
        }
    }

    @media(max-width: 767px) {
        min-width: unset;
        padding: 0 10px;
        border-radius: 15px;

        &:hover {
            span:nth-child(1) {
                opacity: 0;
            }

            span:nth-child(2) {
                opacity: 0;
            }

            &::before {
                opacity: 0;
            }
        }
    }
}

.dashboard-header-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 11px 24px;
    background-color: #000;
    border-radius: 90px;
    border: 1px solid $yellow;
    font-size: 13px;
    font-weight: 600;
    color: $yellow;
    transition: 0.3s;

    & svg{
        fill: $yellow;
    }

    &:hover{
        background-color: #3E3C3C;
    }

    @media(max-width: 1299px){
        span{
            display: none;
        }
    }
}

.dashboard-structure {

    a {
        font-size: 14px;
        font-weight: 400;
        color: $dashboard_link;
        text-decoration: underline;
    }

    h1, h2, h3, h4, h5, h6 {
        margin-bottom: 20px;
    }

    p {
        font-size: 14px;
        font-weight: 400;
        color: $dashboard_color2;
        margin-bottom: 20px;
    }

    p:last-child{
        margin: 0;
    }

    ol {
        display: block;
        list-style-type: decimal;
        margin: 20px 0;
        padding-inline-start: 40px;

        li {
            display: list-item;
            text-align: -webkit-match-parent;
        }
    }

    ul {
        display: block;
        list-style-type: disc;
        margin: 20px 0;
        padding-inline-start: 40px;

        li {
            display: list-item;
            text-align: -webkit-match-parent;
        }
    }

    dl {
        display: block;
        margin: 20px 0;
        padding-inline-start: 0;
    
        dt {
            display: block;
            font-weight: bold;
            margin: 0;
        }
    
        dd {
            display: block;
            margin: 0;
            padding-inline-start: 40px;
        }
    }
}