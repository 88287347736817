.langs{
    padding: 0;
    &__item{
        position: relative;
        z-index: 3;
        &:hover{
            .langs{
                &__sublist{
                    opacity: 1;
                    pointer-events: all;
                }
            }
        }
    }
    &__item-link{
        font-weight: 600;
        font-size: 13px;
        line-height: 130%;
        transition: 0.3s;
        display: flex;
        align-items: center;
        svg{
            stroke: $white  ;
            margin-left: 6px;
            transition: 0.3s;
            margin-top: 2px;
        }
        &:hover{
            color: $yellow;
            svg{
                stroke: $yellow;
            }
        }
    }
    &__sublist{
        position: absolute;
        top: calc(100% + 10px);
        left: -10px;
        background: $white;
        box-shadow: 0 4px 14px rgba(34, 34, 34, 0.28);
        border-radius: 10px;
        width: 54px;
        padding: 16px 12px;
        z-index: 1;
        opacity: 0;
        transition: 0.3s;
        pointer-events: none;
        &::before{
            content: '';
            position: absolute;
            top: -12px;
            left: 0;
            width: 100%;
            height: 12px;
        }
    }
    &__sublist-item{
        margin-bottom: 10px;
        &:last-child{
            margin-bottom: 0;
        }
    }
    &__sublist-link{
        font-weight: 600;
        font-size: 13px;
        line-height: 130%;
        color: $black;
        transition: 0.3s;
        &:hover{
            color: $yellow;
        }
    }
}