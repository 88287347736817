.breadcrumbs{
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  margin: 16px 4px;
  padding: 0;
  overflow-x: auto;

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-track {
    background-color: #fff;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 10px;
  }

  li{
    display: flex;
    margin-bottom: 10px;
    white-space: nowrap;
    
    &::after{
      content: '-';
      margin: 0 0.25rem;
      font-weight: 500;
      font-size: 12px;
      line-height: 140%;
      color: #777777;
    }
    &:last-child{
      &::after{
        display: none;
      }
    }
  }
  a{
    font-weight: 500;
    font-size: 12px;
    line-height: 140%;
    color: #777777;
    transition: 0.3s;
    &:hover{
      color: #777777;
    }
  }
  span{
    font-weight: 500;
    font-size: 12px;
    line-height: 140%;
    color: #777777;
  }
}